import { useState, useRef, useEffect, useCallback } from 'react';

export function useVisible(initialIsVisible: boolean) {
  const [isVisible, setIsVisible] = useState(initialIsVisible);

  const triggerRef = useRef<HTMLDivElement>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    event => {
      if (!isVisible) {
        // skip if dropdown is not visible
        return;
      }

      if (!triggerRef?.current || !dropdownRef?.current) {
        // skip if dropdown is not initialized
        return;
      }

      if (event.composedPath().includes(triggerRef.current) || event.composedPath().includes(dropdownRef.current)) {
        // skip if click was inside dropdown elements
        return;
      }

      setIsVisible(false);
    },
    [isVisible],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  return { dropdownRef, triggerRef, isVisible, setIsVisible };
}
