
const digest = 'b3b1ce9cd3ee5e56da74dace719d6e124e0fb44ba18bbb272bc29f7150b545b2';
const css = `@keyframes styles-module__yERxd-X {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes styles-module__ywPjB-K {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
`;

export default {"fadeIn":"styles-module__yERxd-X","fadeOut":"styles-module__ywPjB-K"};
export { css, digest };
  