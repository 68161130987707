import React, { useState } from 'react';
import RCInputNumber, { InputNumberProps } from 'rc-input-number';
import cx from 'classnames';
import { Icon, IconVariant, Tooltip, ITooltipProps } from '@writerai/ui-atoms';

import 'rc-input-number/assets/index.css';
import styles from './styles.module.css';
import { ValueType } from 'rc-input-number/es/utils/MiniDecimal';

interface IInputNumber extends InputNumberProps {
  tooltip?: Partial<ITooltipProps>;
  disabledDownArrowTooltip?: Partial<ITooltipProps>;
  warning?: boolean;
}

const upHandler = <Icon name={IconVariant.ARROW_UP} />;
const downHandler = <Icon name={IconVariant.ARROW_DOWN} />;

export const InputNumber: React.FC<IInputNumber> = ({
  warning = false,
  disabled,
  tooltip,
  min,
  disabledDownArrowTooltip,
  onChange,
  defaultValue,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(Number(defaultValue) || 1);

  const _onChange = (val: ValueType) => {
    setInputValue(Number(val));
    onChange?.(val);
  };

  return (
    <Tooltip
      disabled={!disabled && !warning}
      title={tooltip?.title || ''}
      {...tooltip}
      tooltipWrapperClassname={styles.tooltipContainer}
    >
      <RCInputNumber
        className={cx(styles.main, { [styles.warning]: warning })}
        upHandler={upHandler}
        downHandler={
          <Tooltip
            disabled={!disabledDownArrowTooltip || inputValue !== min}
            title={disabledDownArrowTooltip?.title || ''}
            placement="bottom"
            tooltipWidth={disabledDownArrowTooltip?.tooltipWidth}
          >
            <span>{downHandler}</span>
          </Tooltip>
        }
        disabled={disabled}
        onChange={_onChange}
        min={min}
        defaultValue={inputValue}
        {...props}
      />
    </Tooltip>
  );
};

export default InputNumber;
