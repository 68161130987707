import type { MouseEvent, FormEvent } from 'react';
import isUndefined from 'lodash/isUndefined';
import type { IDomElementOffset } from '../types/shared';
import type { SearchQueryParam } from '../types/Request';

export const getDomElementOffset = (el: HTMLElement | null): IDomElementOffset => {
  let offset = { top: 0, left: 0 };

  if (el) {
    const rect = el.getBoundingClientRect();
    offset = {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  }

  return offset;
};

export const isElementInViewport = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

/**
 * Return how many elements should be rendered in provided amount fo rows
 * @param parentRef Parent ref
 * @param rows Amunt of rows
 * @returns Amount of elements in all rows
 */
export const calculateElementsInRowVisible = (parentRef, rows, counterWidth) => {
  let elementsInAllRows = 0;
  let nextRowNumber = 0;

  // This is disabled because children can be operated only in For Loop
  // When total children width is calculated
  // we must not return anything unless childrenTotalWidth bigger then parentWidth
  // eslint-disable-next-line consistent-return
  function elementsVisibleInRow(rowNumber: number) {
    if (parentRef.children.length > elementsInAllRows) {
      let childrenTotalWidth = 0;
      const tagElements = parentRef.children;
      const isLastRow = nextRowNumber + 1 === rows;

      for (let i = elementsInAllRows; i < tagElements.length; i++) {
        const childWidth = tagElements[i].clientWidth;
        const parentWidth = parentRef.offsetWidth;
        childrenTotalWidth += childWidth;

        if (childrenTotalWidth > parentWidth || (isLastRow && childrenTotalWidth > parentWidth - counterWidth)) {
          // If it is only one element add +1 to elementsInRow, but in all other cases add index
          elementsInAllRows += i === 0 ? 1 : i;
          nextRowNumber = rowNumber + 1;

          return isLastRow ? elementsInAllRows : elementsVisibleInRow(nextRowNumber);
        }
      }
    }
  }

  const calculatedElementsInRows = elementsVisibleInRow(1);

  if (isUndefined(calculatedElementsInRows)) {
    return parentRef.children.length;
  }

  return calculatedElementsInRows;
};

export const stringToUrl = (str: string): string =>
  str.replace(/^(?:(.*:)?\/\/)?(.*)/i, (match, schema, nonSchemaUrl) => (schema ? match : `http://${nonSchemaUrl}`));

export const openNewTab = (route: string): null => {
  window.open(route, '_blank');

  return null;
};
export const openRequestForm = (): null => openNewTab('https://support.writer.com/hc/en-us/requests/new');
export const getQueryParamDirect = (param: SearchQueryParam): string | null =>
  new URLSearchParams(window.location.search).get(param);

export const stopEventPropagation = (e: MouseEvent | FormEvent) => e.stopPropagation();
export const preventEventDefault = (e: MouseEvent | FormEvent) => e.preventDefault();

export const getModalsRootContainer = (): HTMLElement | undefined =>
  document.getElementById('modals-root') || document.getElementsByTagName('body')[0] || document.body;

export const isEnterButtonClick = (e: KeyboardEvent) => e.key === 'Enter' || e.keyCode === 13;
export const isEscapeButtonClick = (e: KeyboardEvent) => e.key === 'Escape' || e.keyCode === 27;

export function isTextOverflowEllipsisActive(el: HTMLElement | null): boolean {
  return el === null ? false : el.offsetWidth < el.scrollWidth;
}
