import React from 'react';
import cx from 'classnames';
import { ITerm } from '@writerai/common-utils';

import styles from './styles.module.css';
import Text, { TextSize } from '../typography/Text/Text';

export interface ITermLabel {
  /** Set additional css class */
  className?: string;
  /** Set inline css styles */
  style?: React.CSSProperties;
  /** Children passed on as React child */
  children?: React.ReactNode;
  id?: ITerm['id'];
  term: ITerm['term'];
  pos?: ITerm['pos'];
  type?: TermLabelType;
  onClick?: () => void;
}

export enum TermLabelType {
  GREEN,
  BLUE,
  RED,
  ORANGE,
}

export const TermLabel: React.FC<ITermLabel> = ({ className, term, pos, id, onClick, type = TermLabelType.GREEN }) => (
  <div
    key={id}
    className={cx(className, styles.termLabel, {
      [styles.termGreen]: type === TermLabelType.GREEN,
      [styles.termRed]: type === TermLabelType.RED,
      [styles.termBlue]: type === TermLabelType.BLUE,
      [styles.termOrange]: type === TermLabelType.ORANGE,
    })}
    onClick={onClick}
  >
    <Text variant={TextSize.XL}>{term}</Text>
    <Text variant={TextSize.XS}>{pos}</Text>
  </div>
);

export default TermLabel;
