
const digest = '92a3ab385c8a1979f8bee082f5d199ca0127fae3083df445002866ce71c3fe2a';
const css = `.styles-module__aSci9Ud {
  display: flex;
}

.styles-module__F3Hftgv {
  position: relative;
  display: flex;
  align-items: flex-end;
  padding-right: 0;
  padding-left: 0;
  border: 0;
  background-color: transparent;
  color: var(--tabs-link-color);
  cursor: pointer;
  font-size: var(--font-size-xxxl);
  font-weight: var(--font-bold-weight);
  line-height: 28px;
  outline: none;
  transition: color 0.1s ease-out;
}

.styles-module__F3Hftgv:hover,
.styles-module__F3Hftgv.styles-module__NztJuVf {
  color: var(--classic-black);
}

.styles-module__F3Hftgv.styles-module__Qmat3wN {
  color: var(--classic-grey-1-5);
  pointer-events: none;
}

.styles-module__F3Hftgv + .styles-module__F3Hftgv {
  margin-left: 5px;
}

.styles-module__gy7PHOd {
  border-bottom: 2px solid transparent;
  color: currentcolor !important;
}

.styles-module__F3Hftgv.styles-module__NztJuVf > .styles-module__gy7PHOd {
  border-color: var(--classic-black);
}

.styles-module__USJQQr3 {
  width: 30px;
  margin-left: 5px;
  color: currentcolor !important;
  font-size: var(--font-size-m);
  font-weight: var(--font-medium-weight);
  text-align: left;
}
`;

export default {"tabsNav":"styles-module__aSci9Ud","tabButton":"styles-module__F3Hftgv","active":"styles-module__NztJuVf","disabled":"styles-module__Qmat3wN","tabTitle":"styles-module__gy7PHOd","tabCount":"styles-module__USJQQr3"};
export { css, digest };
  