
const digest = 'e35c21970b9d849981f0b5dbf8f64a50693ddeafac3f48c4a4796fb3e39b4587';
const css = `.styles-module__UrS7YZ6 {
  max-width: 129px;
}

.styles-module__XwiL84I {
  max-width: 220px;
}
`;

export default {"posInputGroup":"styles-module__UrS7YZ6","posTooltipTextWrapper":"styles-module__XwiL84I"};
export { css, digest };
  