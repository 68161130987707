import React, { createContext, useContext, FC } from 'react';
import { getModalsRootContainer } from '@writerai/common-utils';

const ModalContext = createContext(getModalsRootContainer);

export const useModalContext = () => useContext(ModalContext);

export const ModalContextProvider: FC<{
  getModalsRootContainer: typeof getModalsRootContainer;
}> = ({ children, getModalsRootContainer }) => (
  <ModalContext.Provider value={getModalsRootContainer}>{children}</ModalContext.Provider>
);
