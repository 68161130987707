import type { SharedQueryParam } from './shared';
import type { IUserProfile } from './User';

export type LogoutParams = {
  [key in SharedQueryParam]?: boolean | string;
};

export interface ILoginResponse {
  loggedUser: IUserProfile;
  // eslint-disable-next-line camelcase
  remember_me: boolean | null;
  result: string;
  token: string;
}

export enum UserTeamRole {
  ORG_ADMIN = 'organization-admin',
  ADMIN = 'admin',
  MEMBER = 'member',
}

export enum UserRole {
  ADMIN = 'admin',
  MEMBER = 'member',
}

export interface IPermission {
  organizations: Record<string, IOrgPermission>;
}

export interface IOrgPermission {
  role: UserRole;
  teams: Record<string, UserRole>;
}

export interface ICheckRegistration {
  valid: boolean;
  exists: boolean;
  confirmed: boolean;
  invited: boolean;
  passwordSet: boolean;
  hasSaml: boolean;
  allowUserCreation: boolean;
  allowDirectPasswordLogin: boolean;
}

export enum SignupType {
  email = 'email',
  saml = 'saml',
  google = 'google',
}

export const checkIfAdminOfAnyTeam = (orgPermissions: IOrgPermission) =>
  orgPermissions && Object.keys(orgPermissions?.teams).some(id => orgPermissions.teams[id] === UserRole.ADMIN);
