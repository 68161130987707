
const digest = 'cd7ede10f64ef8ce5d14760e3955220d1192259f61073e85d62e3c85bbb2b4f8';
const css = `._container_1nicp_1 {
  z-index: 10;
  width: 787px;
  height: auto;
  max-height: 547px;
  position: absolute;
  border-radius: 8px;
  box-shadow: var(--modal-box-shadow);
  background-color: var(--classic-white);
  display: flex;
  flex-direction: column;
}

._container_1nicp_1 *,
._container_1nicp_1 *::after,
._container_1nicp_1 *::before {
  font-family: var(--font-default);
  box-sizing: border-box;
}

._header_1nicp_21 {
  padding: 28px 36px 0;
}

._searchButton_1nicp_25 {
  font-size: 16px;
  font-family: var(--font-default);
  font-weight: var(--font-default-weight);
}

._searchButton_1nicp_25 i {
  padding-left: 8px;
}

._closeButton_1nicp_35 {
  position: absolute;
  top: 18px;
  right: 16px;
  width: auto;
  height: auto;
}

._searchBox_1nicp_43 {
  margin-top: 18px;
}

._main_1nicp_47 {
  height: 100%;
  padding: 22px 36px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

._emptyState_1nicp_55 {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

._tabs_1nicp_62 {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--writer-graynu-1);
}

._hits_1nicp_67 {
  margin-left: -15px;
  width: calc(100% + 30px);
  height: 100%;
  overflow: auto;
}

._hitsSentinel_1nicp_74 {
  display: flex;
  justify-content: center;
}

._hits_1nicp_67::-webkit-scrollbar {
  width: 0;
}

/* Reset for service.portal */
a._hitLink_1nicp_84,
a._hitLink_1nicp_84 * {
  color: inherit !important;
  text-decoration: none !important;
}

._hitContainer_1nicp_90 {
  height: 64px;
  border-radius: 8px;
  padding: 0 14px;
  display: flex;
  align-items: center;
}

._hitContainer_1nicp_90:hover {
  cursor: pointer;
  background-color: var(--writer-graynu-1);
}

._hitContent_1nicp_103 {
  width: 75%;
  display: flex;
  align-items: center;
}

._hitDetails_1nicp_109 {
  width: 25%;
  display: flex;
  flex-direction: column;
  padding-left: 35px;
}

._hitCol_1nicp_116 {
  padding: 0 5px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-align: left;
}

._hitCol_1nicp_116._nameCol_1nicp_125 {
  width: 30%;
  max-width: 30%;
  min-width: 30%;
}

._hitCol_1nicp_116._descriptionCol_1nicp_131 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

._hitCol_1nicp_116 + ._hitCol_1nicp_116._descriptionCol_1nicp_131 {
  padding-left: 25px;
}

._hitCol_1nicp_116:first-child {
  padding-left: 0;
}

._hitCol_1nicp_116:last-child {
  padding-right: 0;
}

mark.ais-Snippet-highlighted,
mark.ais-Highlight-highlighted {
  font-weight: var(--font-bold-weight);
  background-color: var(--writer-blue-2);
}
`;

export default {"container":"_container_1nicp_1","header":"_header_1nicp_21","searchButton":"_searchButton_1nicp_25","closeButton":"_closeButton_1nicp_35","searchBox":"_searchBox_1nicp_43","main":"_main_1nicp_47","emptyState":"_emptyState_1nicp_55","tabs":"_tabs_1nicp_62","hits":"_hits_1nicp_67","hitsSentinel":"_hitsSentinel_1nicp_74","hitLink":"_hitLink_1nicp_84","hitContainer":"_hitContainer_1nicp_90","hitContent":"_hitContent_1nicp_103","hitDetails":"_hitDetails_1nicp_109","hitCol":"_hitCol_1nicp_116","nameCol":"_nameCol_1nicp_125","descriptionCol":"_descriptionCol_1nicp_131"};
export { css, digest };
  