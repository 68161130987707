
const digest = '74ba465682001feaec3842c41f862d2f2e3242ef84c89adcfc18dd3567e078f0';
const css = `.styles-module__CnXCWKl {
  padding: 2px 4px;
  border-radius: 4px;
  font-weight: 500;
}

.styles-module__8o3Zadj {
  font-size: 10px;
  line-height: 15px;
}

.styles-module__8slbKQ- {
  font-size: 8px;
  line-height: 12px;
}

.styles-module__REZQp0q {
  background: var(--classic-grey-1);
}

.styles-module__5tvkYcp {
  background: var(--classic-orange-1);
}

.styles-module__DxmlX7i {
  background: var(--writer-blue-3);
}

.styles-module__5M6pCf9 {
  background: var(--writer-blue-2);
}

.styles-module__rB-PJJ- {
  background: var(--classic-black);
  color: var(--classic-white);
}

.styles-module__0No5vdh {
  background: var(--classic-green-4);
  color: var(--classic-white);
}

.styles-module__-5wTaVF {
  background: var(--writer-yellow-1);
  color: var(--classic-white);
}

.styles-module__OO7OU6Q {
  background: var(--writer-green-3);
}

.styles-module__-Bkt1pe {
  background: var(--classic-orange-1);
}

.styles-module__tPPBOtV {
  background: var(--writer-yellow-transparent-40);
}

.styles-module__5nQ5y9f {
  text-transform: uppercase;
}

.styles-module__e-DX8N8 {
  text-align: left;
}

.styles-module__-yppFNe {
  text-align: center;
}
`;

export default {"tag":"styles-module__CnXCWKl","medium":"styles-module__8o3Zadj","small":"styles-module__8slbKQ-","grey":"styles-module__REZQp0q","orange":"styles-module__5tvkYcp","blue":"styles-module__DxmlX7i","lightBlue":"styles-module__5M6pCf9","black":"styles-module__rB-PJJ-","green":"styles-module__0No5vdh","yellow1":"styles-module__-5wTaVF","lightGreen":"styles-module__OO7OU6Q","lightPeach":"styles-module__-Bkt1pe","lightOrange":"styles-module__tPPBOtV","upperCase":"styles-module__5nQ5y9f","alignLeft":"styles-module__e-DX8N8","alignCenter":"styles-module__-yppFNe"};
export { css, digest };
  