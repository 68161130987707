
const digest = '9d268d5b7a39a7f94e98c87fb0a9d81193f141331529d4191ce19e217d5d9bee';
const css = `.styles-module__tHqPb6w {
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 4px 11px;
  border-radius: 40px;
  margin-top: 8px;
  gap: 8px;
}

.styles-module__tHqPb6w:hover {
  cursor: pointer;
}

.styles-module__tCuiQRa {
  background-color: var(--writer-blue-2);
}

.styles-module__tCuiQRa:hover {
  background-color: var(--writer-blue-3);
}

.styles-module__1VKGlgE {
  background-color: var(--classic-orange-0);
}

.styles-module__1VKGlgE:hover {
  background-color: var(--classic-orange-1);
}

.styles-module__Kn1Gd1n {
  background-color: var(--writer-green-3);
}

.styles-module__Kn1Gd1n:hover {
  background-color: var(--writer-green-4);
}

.styles-module__Kn1Gd1n:first-child {
  margin-top: 8px;
}

.styles-module__Kn1Gd1n > p:last-child {
  color: var(--writer-grey-3);
}
`;

export default {"itemLabel":"styles-module__tHqPb6w","itemBlue":"styles-module__tCuiQRa","itemRed":"styles-module__1VKGlgE","itemGreen":"styles-module__Kn1Gd1n"};
export { css, digest };
  