import React from 'react';

import { IssueCardTypes, rswitch } from '@writerai/common-utils';
import { IssueLabel } from '../../IssueLabel';

import styles from './styles.module.css';

const Suggestion = ({ correction }: { correction?: string[] }) => (
  <>
    {correction?.map((item, index) => (
      <IssueLabel key={`item${index}`} correction style={{ fontSize: 12, marginTop: 7 }}>
        {item}
      </IssueLabel>
    ))}
  </>
);

const Options = ({
  cardType,
  onApplySuggestion,
  correction,
}: {
  cardType: IssueCardTypes;
  onApplySuggestion?: (correction: string) => void | Promise<unknown>;
  correction?: string[];
}) => (
  <div className={styles.optionsContainer}>
    {rswitch(cardType, {
      [IssueCardTypes.ACCEPT_ALL_CHANGES]: (
        <div
          className={styles.acceptAllButton}
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
            onApplySuggestion?.(correction ? correction[0] : '');
          }}
        >
          Accept suggestion
        </div>
      ),
      [IssueCardTypes.SUGGESTION]: <Suggestion correction={correction} />,
      default: <></>,
    })}
  </div>
);

export default React.memo(Options);
