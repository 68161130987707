import { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { FieldAppSDK } from '@contentful/app-sdk';
import { Button, Tooltip } from '@contentful/forma-36-react-components';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { CloseIcon } from '@writerai/component-library';
import getField from './getField';
import { trackEvent, trimTextToClosestFoundedCharacter } from './utils';
import logo from '../../assets/icons/logo.svg';

import '@contentful/forma-36-react-components/dist/styles.css';
import styles from './style.module.scss';

const AGREED_WITH_TERMS_KEY = 'agreedWithTerms';

export const Field = ({ sdk }: { sdk: FieldAppSDK }) => {
  const [score, setScore] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowScore, setIsShowScore] = useState(false);
  const [isNoContentToCheckMessage, setIsNoContentToCheckMessage] = useState(false);
  const [isTextChanged, setIsTextChanged] = useState(false);
  const [isUserAgreed, setIsUserAgreed] = useState(localStorage.getItem(AGREED_WITH_TERMS_KEY) === 'true');
  const firstTextInitDone = useRef(false);
  const checkContentRequestAbortController = useRef<AbortController | undefined>();

  useEffect(() => {
    const onStorageListener = (e: StorageEvent) => {
      if (e.key === AGREED_WITH_TERMS_KEY && e.newValue === 'true') {
        setIsUserAgreed(true);
      }
    };
    window.addEventListener('storage', onStorageListener);
    return () => window.removeEventListener('storage', onStorageListener);
  }, []);

  const onChangeText = useCallback(() => {
    if (firstTextInitDone.current && isShowScore) {
      setIsTextChanged(true);
    }
    firstTextInitDone.current = true;
  }, [isShowScore]);

  const onAgreeTerms = () => {
    if (!isUserAgreed) {
      localStorage.setItem(AGREED_WITH_TERMS_KEY, 'true');
      setIsUserAgreed(true);
    }
  };

  useEffect(() => {
    const unsubscribe = sdk.field.onValueChanged(onChangeText);
    return () => {
      unsubscribe();
      firstTextInitDone.current = false;
    };
  }, [sdk.field, onChangeText]);

  useEffect(() => {
    sdk.window.startAutoResizer();
    return () => sdk.window.stopAutoResizer();
  }, [sdk.window]);

  const getContentScoreFromApi = async (contentToSend: string) => {
    const res = await axios.post('https://zddaq00046a22k9p.us-east-1.aws.endpoints.huggingface.cloud', {
      inputs: contentToSend,
      parameters: { top_k: null },
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: "Bearer <hf_token>",
        'Content-Type': 'application/json',
      },
      signal: checkContentRequestAbortController.current?.signal,
    });
    return res?.data?.find((result: { label: string; score: number }) => result.label === 'LABEL_1')?.score;
  };

  const checkContent = async () => {
    trackEvent(isShowScore ? "[Contentful] Clicked Check Again Button" : "[Contentful] Launched AI Detector");

    onAgreeTerms();
    setIsTextChanged(false);
    setIsNoContentToCheckMessage(false);
    setIsLoading(true);
    setIsShowScore(true);
    setScore(null);
    try {
      const fieldValue = sdk.field.getValue();
      const fieldType = sdk.field.type;
      const isRichText = fieldType === 'RichText';
      const contentToSend = isRichText ? documentToPlainTextString(fieldValue) : fieldValue;
      if (!contentToSend?.length) {
        setScore(0);
        setIsNoContentToCheckMessage(true);
        return ;
      }

      checkContentRequestAbortController.current = new AbortController();

      let score;
      if (contentToSend.length > 1500) {
        let contentCompletelyChecked = false;
        let nextStartFrom = 0;
        const contentChunks = [];
        while (!contentCompletelyChecked) {
          const currentChunk = contentToSend.slice(nextStartFrom, nextStartFrom + 1500);
          if (currentChunk.length === 0) {
            contentCompletelyChecked = true;
            continue;
          }

          const chunksEndingsGroups = [['. ', '? ', '! '], ['.', '?', '!'], [',', ';'], [' ']];
          let updatedChunk: string | undefined;
          chunksEndingsGroups.some((chunksEndingsGroup) => {
            updatedChunk = trimTextToClosestFoundedCharacter(currentChunk, chunksEndingsGroup);
            return updatedChunk !== undefined;
          });

          if (updatedChunk) {
            contentChunks.push(updatedChunk.trim());
            nextStartFrom += updatedChunk.length;
            continue;
          }

          contentChunks.push(currentChunk.trim());
          nextStartFrom += currentChunk.length;
        }

        const chunksScores = await Promise.all(contentChunks.map(getContentScoreFromApi));
        score = chunksScores.reduce((a, b) => a + b) / chunksScores.length;
      } else {
        score = await getContentScoreFromApi(contentToSend);
      }

      if (score) {
        setScore(Math.round(score * 100));
      } else {
        setScore(null);
      }
    } catch (e) {
      setScore(null);
    } finally {
      setIsLoading(false);
    }
  };

  const closeScore = async () => {
    trackEvent("[Contentful] Closed AI Detector");

    setIsTextChanged(false);
    setIsShowScore(false);
    setScore(null);
    checkContentRequestAbortController.current?.abort();
  };

  const getMessage = () => {
    if (isTextChanged) {
      return "Looks like you made changes.\nTry checking again.";
    }
    if (isNoContentToCheckMessage) {
      return "You don’t have any content yet.";
    }
    if (score) {
      if (score < 91) {
        return "You should edit your text until there’s less detectable AI content";
      } else if (score >= 91 && score <= 95) {
        return "Looking great!";
      } else {
        return "Fantastic!";
      }
    }
    return '';
  };

  const getValueClassName = () => {
    if (score) {
      if (score < 91) {
        return styles.bad;
      } else if (score >= 91 && score <= 95) {
        return styles.good;
      } else {
        return styles.perfect;
      }
    }
    return '';
  };

  return (
    <div className={styles.main}>
      <div className={styles.main__control}>
        {isShowScore ? (
          <div className={styles.buttonsGroup}>
            <Button buttonType='muted' className={styles.checkButton} onClick={checkContent}>
              <div className={`${styles.checkButtonContent} ${styles.wideButtonContent}`}>
                <img src={logo} alt='logo' className={styles.retryButtonIcon} />
                Check again
              </div>
            </Button>
          </div>
        ) : (
          <Button buttonType='muted' className={styles.checkButton} onClick={checkContent}>
            <div className={styles.checkButtonContent}>
              <img src={logo} alt='logo' />
              Check for AI-generated content
            </div>
          </Button>
        )}

        <div className={`${styles.score} ${isShowScore ? '' : styles.hidden}`}>
          <div className={`${styles.value} ${getValueClassName()}`}>
            <div className={styles.valueTop}>
              <div className={styles.valueContent}>
                {isLoading ? <div className={styles.spinner} /> : <p>{score || 0}%</p>}
              </div>
              <div className={`${styles.valueDescription} ${isNoContentToCheckMessage ? styles.short : ''}`}>{isNoContentToCheckMessage ? "No\ncontent" : "Human-generated content"}</div>
            </div>
            <div className={styles.progressBarRail}>
              <div className={styles.progressBarFill} style={{ width: `${score || 0}%` }} />
            </div>
          </div>
          <div className={styles.separator} />
          <div className={styles.message}>{getMessage()}</div>
          {isShowScore ? (
            <Tooltip content='Close' place='bottom'>
              <CloseIcon containerSize={32} iconSize={20} onClick={closeScore} className={styles.closeButtonIcon} />
            </Tooltip>
          ) : null}
        </div>
      </div>
      {!isUserAgreed && (
        <div className={styles.main__changed}>
          By clicking <i>Check for AI-generated content</i>, you agree to Writer’s <a href='https://writer.com/terms/' target='_blank' rel='noreferrer'>terms</a> and <a href='https://writer.com/privacy/' target='_blank' rel='noreferrer'>privacy policy</a>.
          <CloseIcon containerSize={16} iconSize={16} onClick={onAgreeTerms} className={styles.messageCloseButtonIcon} />
        </div>
      )}
      <div className={styles.main__content}>{getField(sdk)}</div>
    </div>
  );
};
