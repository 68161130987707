
const digest = 'c3b56328f65bcdd6c1c8dbdb95ce47cf64fc452c8bae744ab028602fee87ee61';
const css = `.styles-module__1asZf98 {
  display: flex;
  margin: auto;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.6s ease-in;
}

.styles-module__iAmZCYd {
  display: flex;
  max-width: 480px;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 0 11px;
  border-radius: 60px;
  margin: auto;
  background: #fff;
  box-shadow: 0 3px 40px rgb(172 185 220 / 40%);
}

.styles-module__Br21iqt {
  opacity: 1;
  pointer-events: auto;
}

.styles-module__06hNIuP {
  width: auto;
  justify-content: center;
}

.styles-module__bt5VPY9 {
  position: relative;
  margin: 0 1px;
}

.styles-module__1cc6QIq {
  background-color: #5eebc1;
}

.styles-module__S7N491I {
  display: flex;
  width: 90px;
  align-items: center;
  justify-content: space-between;
  margin: 0 19px 0 8px;
}

.styles-module__5XWBhF7 {
  display: flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.styles-module__nvrtgOH {
  display: flex;
  align-items: center;
}

.styles-module__UO079xo {
  margin: 0 17px 0 6px;
}

.styles-module__qCwG-PB {
  position: absolute;
  right: 0;
  bottom: -8px;
  left: 0;
  margin: auto;
}

.styles-module__5k7qAXu {
  margin: 0 13px;
  color: var(--classic-grey-2);
}

.styles-module__NBzO2ZM {
  display: flex;
  min-width: 173px;
  height: 100%;
}

.styles-module__w-a2AKD {
  display: flex;
  align-items: center;
  border-left: var(--gray-border);
}
`;

export default {"wrapper":"styles-module__1asZf98","container":"styles-module__iAmZCYd","visible":"styles-module__Br21iqt","justViewing":"styles-module__06hNIuP","collaborationUser":"styles-module__bt5VPY9","userTyping":"styles-module__1cc6QIq","singleUserTyping":"styles-module__S7N491I","userAvatarWrapper":"styles-module__5XWBhF7","users":"styles-module__nvrtgOH","usersMargined":"styles-module__UO079xo","userLoading":"styles-module__qCwG-PB","lockedText":"styles-module__5k7qAXu","lockedWrapper":"styles-module__NBzO2ZM","lockedSection":"styles-module__w-a2AKD"};
export { css, digest };
  