
const digest = '19a99ee9f66ee387510a3dd6c493bef284b302a04685db4482ebe4119a96be06';
const css = `.styles-module__lACgrE8::after {
  margin: 0 10px;
  content: '•';
  line-height: 18px;
}

.styles-module__KLcurQp {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1.3px;
  line-height: 15px;
  text-transform: uppercase;
}

.styles-module__ZVOByZa {
  display: block;
  width: fit-content;
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 10px;
  background: var(--classic-grey-2);
  color: var(--white);
  cursor: default;
  user-select: none;
  white-space: nowrap;
}

/* Dot Loader */
.styles-module__PAKzama {
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  width: auto;
  height: 100%;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.styles-module__PAKzama + i,
.styles-module__PAKzama + span,
.styles-module__PAKzama + svg,
.styles-module__PAKzama + svg + span {
  display: none;
}

.styles-module__PAKzama .styles-module__dz-aWYb {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 0 4px;
  animation: styles-module__J4-vxaZ 1.4s infinite ease;
  background-color: currentcolor;
}

.styles-module__3gWmGxI .styles-module__dz-aWYb {
  margin: 0 2px;
}

.styles-module__eeu2Rbt.styles-module__oyGQj7T .styles-module__PAKzama .styles-module__dz-aWYb {
  background-color: var(--classic-grey-1-5);
}

.styles-module__PAKzama .styles-module__dz-aWYb:nth-child(2) {
  animation-delay: 0.35s;
}

.styles-module__PAKzama .styles-module__dz-aWYb:nth-child(3) {
  animation-delay: 0.7s;
}

@keyframes styles-module__J4-vxaZ {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(2, 2);
  }

  100% {
    transform: scale(1, 1);
  }
}

/* EmptyStateText */
.styles-module__wWMNh3H {
  color: var(--classic-grey-1-5) !important;
  text-align: center !important;
}

/* CreatorDetailsSpan */
.styles-module__wLob0sA {
  font-weight: 400;
}

.styles-module__wLob0sA b {
  font-weight: 600 !important;
}

/* ExtraDetailRow */
.styles-module__H2-TS5n {
  border-bottom: 0 !important;
}
`;

export default {"dotSeparator":"styles-module__lACgrE8","title":"styles-module__KLcurQp","labelledTags":"styles-module__ZVOByZa styles-module__KLcurQp","buttonLoader":"styles-module__PAKzama","buttonLoaderDot":"styles-module__dz-aWYb","dotKeyframes":"styles-module__J4-vxaZ","buttonLoaderCompact":"styles-module__3gWmGxI","button":"styles-module__eeu2Rbt","default":"styles-module__oyGQj7T","zeroStateText":"styles-module__wWMNh3H","createdDetailsSpan":"styles-module__wLob0sA","extraDetailRow":"styles-module__H2-TS5n"};
export { css, digest };
  