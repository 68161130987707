import React from 'react';
import cx from 'classnames';

import styles from './styles.module.css';
import { IconVariant, iconsList } from './iconsList';

export { IconVariant } from './iconsList';

export interface IconProps {
  className?: string;
  style?: React.CSSProperties;
  name: IconVariant;
  width?: number;
  height?: number;
  color?: string;
  onClick?: (e: React.MouseEvent) => void;
}

export const Icon: React.FC<IconProps> = ({ width, height, color, name, className, style, onClick, ...props }) => {
  const iconProps = width && height ? { height, width, color, ...props } : { ...props, color };
  const icon = iconsList[name];

  return (
    <i className={cx(styles.container, className)} style={style} onClick={onClick}>
      {icon(iconProps)}
    </i>
  );
};

export default Icon;
