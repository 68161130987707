import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';
import {
  Text,
  TextColor,
  TextSize,
  Input,
  Icon,
  IconVariant,
  Tooltip,
  TermLabel,
  MuiFormControl,
  useVisible,
} from '@writerai/ui-atoms';

import {
  createTermMapper,
  ILinkedTerm,
  initAddTermState,
  ITerm,
  ITermCreateAndUpdate,
  stopEventPropagation,
  suggestionsDefaultTooltips,
  termToLinkedTerm,
} from '@writerai/common-utils';

import AddNewRowButton from '../AddNewRowButton';
import ThreeDotsLoader from '../ThreeDotsLoader';

import styles from './styles.module.css';

export interface ITermSuggestionsGroupProps {
  termId: number;
  linkedTerms: ILinkedTerm[];
  terms: ITerm[];
  searchTerm: string;
  handleSearch: (term: string) => void;
  onTermClick: (termId: ITerm['id']) => void;
  onChange: (approvedTerms: ILinkedTerm[]) => void;
  onTermCreated: (terms: ITermCreateAndUpdate) => Promise<ITerm>;
}

export const SuggestionsGroup = ({
  termId,
  linkedTerms,
  terms,
  searchTerm,
  handleSearch,
  onTermClick,
  onChange,
  onTermCreated,
}: ITermSuggestionsGroupProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const [isCreateShown, setIsCreateShown] = useState(false);
  const [newTerm, setNewTerm] = useState<ITerm | undefined>();
  const { triggerRef, dropdownRef, isVisible, setIsVisible } = useVisible(false);

  const toggleDropDown = useCallback(() => setIsVisible(prev => !prev), [setIsVisible]);

  const onAddNewRowClick = () => {
    handleSearch('');
  };

  const onTermChange = (term: string) => {
    setIsLoading(true);
    handleSearch(term);
  };

  const onCreateTerm = async () => {
    if (isCreateLoading) {
      return;
    }

    setIsCreateLoading(true);

    const newTerm = {
      ...initAddTermState,
      term: searchTerm,
    };

    const createdTerm = await onTermCreated(createTermMapper(newTerm));
    setNewTerm(createdTerm);
  };

  const onTermAdded = (term: ITerm) => {
    onChange([...linkedTerms, termToLinkedTerm(termId, term)]);
  };

  const handleRemoveTerm = (removedTerm: ILinkedTerm) => {
    onChange(linkedTerms.filter(term => term.linkedTermId !== removedTerm.linkedTermId));
  };

  useEffect(() => {
    const isTermInTheTermsList = terms.some(term => term.term === searchTerm);

    setIsCreateShown(!isTermInTheTermsList);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terms]);

  useEffect(() => {
    if (!isEmpty(newTerm)) {
      setIsCreateLoading(false);
      onTermAdded(newTerm!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTerm]);

  return (
    <MuiFormControl className={cx(styles.formControl)}>
      <div className={styles.header}>
        <Text variant={TextSize.XL} bold>
          Replace with
        </Text>
        <Tooltip
          title={
            <div className={styles.infoTooltipContainer}>
              <Text variant={TextSize.S} color={TextColor.WHITE}>
                {suggestionsDefaultTooltips.suggestedApprovedTerms}
              </Text>
            </div>
          }
          placement="top"
          tooltipWidth={220}
        >
          <span>
            <Icon name={IconVariant.INFO_OUTLINED} />
          </span>
        </Tooltip>
      </div>

      <div className={styles.termsWrapper}>
        {linkedTerms.map(linkedTerm => (
          <div key={linkedTerm.linkedTermId}>
            <TermLabel
              className={styles.termOption}
              term={linkedTerm.term}
              id={linkedTerm.id}
              pos={linkedTerm.pos}
              onClick={() => onTermClick(linkedTerm.linkedTermId)}
            />

            <div onClick={() => handleRemoveTerm(linkedTerm)}>
              <Icon name={IconVariant.REMOVE_BLACK} width={12} height={12} />
            </div>
          </div>
        ))}
      </div>

      <div className={styles.dropDownWrapper}>
        <div ref={triggerRef} onClick={toggleDropDown}>
          {linkedTerms.length < 3 && <AddNewRowButton onClick={onAddNewRowClick} label="Select an approved term" />}
        </div>

        {isVisible && (
          <div className={styles.menuItemsContainer} ref={dropdownRef} onClick={toggleDropDown}>
            <div onClick={stopEventPropagation}>
              <Input
                className={styles.input}
                value={searchTerm}
                onChange={e => {
                  onTermChange(e.target.value);
                }}
              />

              <div>
                <Icon name={IconVariant.CLOSE} width={16} height={16} />
              </div>
            </div>

            {!isLoading && isCreateShown && searchTerm.length > 1 && (
              <div className={styles.createTerm}>
                <Text smallCaps>Create approved term</Text>

                <div>
                  <Text className={styles.termBlue} variant={TextSize.XL}>
                    {searchTerm}
                  </Text>

                  <AddNewRowButton className={styles.noPaddings} onClick={onCreateTerm} label="" />

                  {isCreateLoading && <ThreeDotsLoader className={styles.loadingCreatedTerm} />}
                </div>
              </div>
            )}

            <div className={cx(styles.showTerms, { [styles.showTermsSmaller]: isCreateShown })}>
              {isLoading ? (
                <ThreeDotsLoader className={styles.loading} />
              ) : (
                <div className={styles.terms}>
                  {terms
                    .filter(term => !linkedTerms.some(lTerm => lTerm.linkedTermId === term.id))
                    .map(term => (
                      <TermLabel
                        key={term.id}
                        term={term.term}
                        id={term.id}
                        pos={term.pos}
                        onClick={() => onTermAdded(term)}
                      />
                    ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </MuiFormControl>
  );
};

export default React.memo(SuggestionsGroup);
