import { OrganizationType } from '../types/Organization';

export const CONTACT_SALES_LINK = 'https://go.writer.com/contact-sales';
export const CHILLI_PIPER_CONTACT_LINK = 'https://writer.na.chilipiper.com/router/in_product_router';
export const SESSION_COOKIE = 'qToken';
export const LEGACY_ORG_COOKIE = { name: 'organization_type', value: OrganizationType.OLD };
export const SUPPORT_EMAIL = 'support@writer.com';

export const REACT_APP_CHROME_EXTENSION_ID = 'hngnkmianenpifegfoggnkamjnffiobn';
export const REACT_APP_WORD_EXTENSION_ID = 'WA200001548';
export const REACT_APP_FIGMA_EXTENSION_ID = '1010580776466011580/Writer-for-Figma';
export const REACT_APP_MAC_EXTENSION_ID = 'Writer.dmg';
export const REACT_APP_WINDOWS_EXTENSION_ID = 'WriterInstaller.exe';
export const REACT_APP_OUTLOOK_EXTENSION_ID = 'WA200002646';
export const REACT_APP_SUPPORT_PAGE_URL = 'https://support.writer.com/';
export const REACT_APP_SESSION_COOKIE = 'qToken';
export const REACT_APP_SESSION_REFRESH_COOKIE = 'qRefreshToken';

export const REDIRECT_DOC_ID = -1;
export const CREATE_NEW_DOC_ID = 0;
export const EMPTY_EDITOR_DOC_ID = 1;
export const ALLOW_LIST_LIMIT = 1000;
export const DEFAULT_TEAM_AVATAR_COLOR = 'var(--classic-white)';
export const USER_LOGIN_DETECTED = 'userLoginDetected';
export const DEFAULT_PORTAL_DOMAIN = '.styleguide.com';
export const NUMBER_OF_WORDS_FOR_AUTO_WRITE = 5;
export const DEBOUNCE_TIME_ON_TYPING = 500;
export const MAX_TIME_TO_CHECK_FOR_INSTALLED_GRAMMARLY = 180000;
export const POLLING_EXTENSION_TIMEOUT = 4000;
export enum AVATAR_COLOR {
  AVATAR_1 = 'var(--avatar-1)',
  AVATAR_2 = 'var(--avatar-2)',
  AVATAR_3 = 'var(--avatar-3)',
  AVATAR_4 = 'var(--avatar-4)',
  AVATAR_5 = 'var(--avatar-5)',
  AVATAR_6 = 'var(--avatar-6)',
  AVATAR_7 = 'var(--avatar-7)',
  AVATAR_8 = 'var(--avatar-8)',
}
export const DEFAULT_BILLING_CHECKOUT_SEATS = 10;
export const NUMBER_OF_DAYS_BEFORE_FREE_PLAN_WARNING = 15;
export const NUMBER_OF_DAYS_BEFORE_ACCOUNT_DOWNGRADE = 30;
export const AUTH_TOKEN_NAME = 'x-auth-token';
export const AUTH_SESSION_REFRESH_INTERVAL_MS = 10000;
export const AUTH_SESSION_RETRY_INTERVAL_MS = 5000;
export const AUTH_SESSION_RETRIES = 24;
