import React, { ReactNode, useCallback } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';
import { Icon, IconVariant } from '@writerai/ui-atoms';

export interface IssueLabelProps {
  className?: string;
  bold?: boolean;
  strikethrough?: boolean;
  correction?: boolean;
  error?: boolean;
  arrow?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
  children?: ReactNode;
}

export const IssueLabel: React.FC<IssueLabelProps> = ({
  children,
  bold,
  correction,
  error,
  strikethrough,
  arrow,
  style,
  className,
  onClick,
}) => {
  const onApplyClick = useCallback(
    (event: React.MouseEvent) => {
      if (onClick) {
        event.preventDefault();
        event.stopPropagation();
        onClick && onClick();
      }
    },
    [onClick],
  );

  return (
    <div className={styles.container} style={style}>
      {arrow && <Icon className={styles.icon} name={IconVariant.ARROW_RIGHT_ALT}></Icon>}
      <span
        className={cx(
          styles.issueLabel,
          {
            [styles.strikethrough]: strikethrough,
            [styles.bold]: bold,
            [styles.buttonLike]: correction || error,
            [styles.correction]: correction,
            [styles.error]: error,
          },
          className,
        )}
        onClick={onApplyClick}
      >
        {children}
      </span>
    </div>
  );
};

export default React.memo(IssueLabel);
