import React, { MouseEventHandler } from 'react';
import cx from 'classnames';
import '@writerai/common-utils';

import {
  Icon,
  IconVariant,
  Heading,
  HeadingVariant,
  Text,
  TextColor,
  TextSize,
  Button,
  ButtonTypes,
} from '@writerai/ui-atoms';

import styles from './styles.module.css';

import SnippetsSidebarBanner from '../../assets/banners/snippetsSidebar.svg';

export enum EducationalBannerLocation {
  TERMS = 'terms',
  SNIPPETS = 'snippets',
  SNIPPETS_SIDEBAR = 'snippets_sidebar',
}

export interface LocationsConfig {
  location: EducationalBannerLocation;
  title: React.ReactNode;
  description: (
    onPrimaryActionClick: MouseEventHandler | undefined,
    onSecondaryActionClick: MouseEventHandler | undefined,
    onTertiaryActionClick?: MouseEventHandler | undefined,
  ) => React.ReactNode;
  image?: ({ className }) => React.ReactNode;
}

export interface IEducationalBannerProps {
  /** Position of banner */
  location: EducationalBannerLocation;
  /** Classname for banner */
  bannerClassName?: string;
  /** State of close banner button */
  closeButtonVisible?: boolean;
  /** Callback when user click close banner button */
  onCloseClick?: () => void;
  /** Callback when user click on primary CTA button */
  onPrimaryActionClick?: () => void;
  /** Callback when user click on secondary CTA button */
  onSecondaryActionClick?: () => void;
  onTertiaryActionClick?: () => void;
}

const Banner = ({ className, location }) => {
  const isTermsLocation = location === EducationalBannerLocation.TERMS;
  const isSnippetsLocation = location === EducationalBannerLocation.SNIPPETS;
  const isSnippetsSidebarLocation = location === EducationalBannerLocation.SNIPPETS_SIDEBAR;

  return (
    <div
      className={cx(
        cx(styles.banner, {
          [styles.bannerTerms]: isTermsLocation,
          [styles.bannerSnippets]: isSnippetsLocation,
          [styles.bannerSnippetsSidebar]: isSnippetsSidebarLocation,
        }),
      )}
    >
      {isTermsLocation && <div className={cx(styles.termsBanner, className)} />}
      {isSnippetsLocation && <div className={cx(styles.snippetsBanner, className)} />}
      {isSnippetsSidebarLocation && <SnippetsSidebarBanner />}
    </div>
  );
};

const CloseButton = ({ onCloseClick }) => (
  <div className={styles.containerClose}>
    <div className={cx(styles.containerCloseIcon, styles.clickable)} onClick={onCloseClick} title="Close">
      <Icon name={IconVariant.CLOSE} width={17} height={17} />
    </div>
  </div>
);

const locationsConfig: LocationsConfig[] = [
  {
    location: EducationalBannerLocation.TERMS,
    title: (
      <Heading bold className={styles.containerBannerHeading}>
        Write consistently with terms
      </Heading>
    ),
    description: (onPrimaryActionClick, onSecondaryActionClick, onTertiaryActionClick) => (
      <div>
        <Text variant={TextSize.XXL} className={styles.containerBannerTextHeading}>
          Add your brand terms to get inline suggestions as you write
        </Text>
        <Text color={TextColor.GREY2} className={styles.containerBannerText}>
          Start{' '}
          <span className={styles.clickable} onClick={onPrimaryActionClick}>
            by adding
          </span>{' '}
          or{' '}
          <span className={styles.clickable} onClick={onTertiaryActionClick}>
            importing terms.
          </span>
          <br />
          Or, watch our{' '}
          <button
            type="button"
            data-beacon-article-modal="44"
            className={cx(styles.clickable, styles.cleanButton)}
            onClick={onSecondaryActionClick}
          >
            quick start video
          </button>
          .
        </Text>
      </div>
    ),
    image: ({ className }) => <Banner location={EducationalBannerLocation.TERMS} className={className} />,
  },
  {
    location: EducationalBannerLocation.SNIPPETS,
    title: (
      <Heading className={styles.containerBannerHeading} bold>
        Save
        <br /> time with <br />
        snippets
      </Heading>
    ),
    description: (onPrimaryActionClick, onSecondaryActionClick) => (
      <div>
        <Text variant={TextSize.XXL} className={styles.containerBannerTextHeading}>
          Add frequently-typed text and reuse with a simple shortcut
        </Text>
        <Text color={TextColor.GREY2} className={styles.containerBannerText}>
          Start{' '}
          <span className={styles.clickable} onClick={onPrimaryActionClick}>
            by adding a snippet.
          </span>
          <br />
          Or, watch our{' '}
          <button
            type="button"
            data-beacon-article-modal="76"
            className={cx(styles.clickable, styles.cleanButton)}
            onClick={onSecondaryActionClick}
          >
            quick start video
          </button>
          .
        </Text>
      </div>
    ),
    image: ({ className }) => <Banner location={EducationalBannerLocation.SNIPPETS} className={className} />,
  },
  {
    location: EducationalBannerLocation.SNIPPETS_SIDEBAR,
    title: (
      <Heading variant={HeadingVariant.H3} className={cx(styles.containerBannerHeadingSmall, styles.alignCenter)} bold>
        Save time
        <br /> with snippets
      </Heading>
    ),
    image: ({ className }) => <Banner location={EducationalBannerLocation.SNIPPETS_SIDEBAR} className={className} />,
    description: (onPrimaryActionClick, onSecondaryActionClick) => (
      <div>
        <div className={cx(styles.containerBannerDescriptionSmall, styles.alignCenter)}>
          <Text color={TextColor.GREY} variant={TextSize.S} className={styles.containerBannerTextHeadingSmall}>
            Add frequently-typed text and reuse it anywhere with Writer.
          </Text>
        </div>
        <Button type={ButtonTypes.BLACK} className={styles.buttonFullWidth} onClick={onPrimaryActionClick}>
          Add a snippet
        </Button>
        <div className={cx(styles.containerBannerDescriptionSmall, styles.alignCenter)}>
          <Text color={TextColor.GREY} variant={TextSize.S} className={styles.containerBannerTextHeadingSmall}>
            Watch our snippets {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className={cx(styles.clickable, styles.buttonBlue, styles.cleanButton)} onClick={onSecondaryActionClick}>
              quick start video
            </a>{' '}
            to learn more.
          </Text>
        </div>
      </div>
    ),
  },
];

export const EducationalBanner: React.FC<IEducationalBannerProps> = ({
  bannerClassName,
  location,
  closeButtonVisible = true,
  onCloseClick,
  onPrimaryActionClick,
  onSecondaryActionClick,
  onTertiaryActionClick,
}) => {
  const locationConfig = locationsConfig.find(c => c.location === location) as LocationsConfig;
  const isSnippetsSidebarLocation = location === EducationalBannerLocation.SNIPPETS_SIDEBAR;

  return (
    <div className={styles.container}>
      <div
        className={cx(styles.containerBanner, {
          [styles.containerBannerDirectionColumn]: isSnippetsSidebarLocation,
        })}
      >
        {closeButtonVisible && <CloseButton onCloseClick={onCloseClick} />}
        <div className={styles.containerBannerImage}>
          {locationConfig.image && locationConfig.image({ className: bannerClassName })}
        </div>
        <div
          className={cx(styles.containerBannerContent, {
            [styles.containerBannerContentSmall]: isSnippetsSidebarLocation,
          })}
        >
          <div className={styles.containerBannerTitle}>{locationConfig.title}</div>
          <div>
            {locationConfig.description &&
              locationConfig.description(onPrimaryActionClick, onSecondaryActionClick, onTertiaryActionClick)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationalBanner;
