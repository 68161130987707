import { RefObject, useState } from 'react';
import { isUndefined } from 'lodash/fp';

import { DropdownPlacement } from './index';
import { useDebounce } from '../../hooks/useDebounce';

type DropdownPositionType = {
  left?: number | string;
  right?: number | string;
  top?: number;
  bottom?: number;
};

export const useDropdownCoordinates = (
  triggerRef: RefObject<HTMLDivElement>,
  placement: DropdownPlacement,
  isVisible: boolean,
) => {
  const [portalCoords, setPortalCoords] = useState<DropdownPositionType>();
  const debouncedPortalCoords = useDebounce(portalCoords, 100, () => {
    if (isVisible && triggerRef.current) {
      onUpdatePortalCoords();
    }
  });

  const onUpdatePortalCoords = () => {
    /*
     * It's important to use rect.left instead of rect.x and rect.top instead of rect.y
     * due to limitation of Edge Legacy where we have integration (MS Word add-in).
     *
     * Edge Legacy uses ClientRect interface instead of DOMRect for getBoundingClientRect return type.
     * ClientRect interface has no x and y properties.
     *
     * window.pageYOffset is deprecated but used here for IE11 compatibility.
     *
     * Reference: WORD-44, https://github.com/facebook/flow/issues/5475
     * */
    const rect = triggerRef.current?.getBoundingClientRect();

    if (rect) {
      const coords: DropdownPositionType = {};
      const scrollY = isUndefined(window.scrollY) ? window.pageYOffset : window.scrollY;

      if ([DropdownPlacement.TOP_LEFT, DropdownPlacement.BOTTOM_LEFT].includes(placement)) {
        coords.left = rect.left;
      } else if (placement === DropdownPlacement.RIGHT) {
        coords.left = rect.left + rect.width;
      } else if (placement === DropdownPlacement.TOP) {
        coords.left = 'auto';
      } else {
        coords.right = window.innerWidth - (rect.left + rect.width);
      }

      if ([DropdownPlacement.BOTTOM_LEFT, DropdownPlacement.BOTTOM_RIGHT].includes(placement)) {
        coords.top = rect.top + rect.height + scrollY;
      } else if (placement === DropdownPlacement.RIGHT) {
        coords.top = rect.top - rect.height + scrollY;
      } else {
        coords.bottom = window.innerHeight - rect.top + scrollY;
      }

      setPortalCoords(coords);
    }
  };

  return { debouncedPortalCoords, onUpdatePortalCoords };
};
