
const digest = '9b5178770ce7bfe3ceb2a4832e5ec3683f0333a54847f4ac8ce914d36b3c4ef0';
const css = `.styles-module__5DKoPUx {
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 4px 11px;
  border-radius: 40px;
  margin: 8px 0;
  gap: 8px;
}

.styles-module__5DKoPUx:hover {
  cursor: pointer;
}

.styles-module__t-HpdoC {
  background-color: var(--writer-blue-2);
}

.styles-module__t-HpdoC:hover {
  background-color: var(--writer-blue-3);
}

.styles-module__SocAoh1 {
  background-color: var(--classic-orange-0);
}

.styles-module__SocAoh1:hover {
  background-color: var(--classic-orange-1);
}

.styles-module__qtie84Q {
  background-color: var(--writer-green-3);
}

.styles-module__qtie84Q:hover {
  background-color: var(--writer-green-4);
}

.styles-module__dVj5f7T {
  background-color: #f8e9d2;
}

.styles-module__dVj5f7T:hover {
  background-color: #f8e9d2;
}

.styles-module__qtie84Q:first-child {
  margin-top: 8px;
}

.styles-module__qtie84Q > p:last-child {
  color: var(--writer-grey-3);
}
`;

export default {"termLabel":"styles-module__5DKoPUx","termBlue":"styles-module__t-HpdoC","termRed":"styles-module__SocAoh1","termGreen":"styles-module__qtie84Q","termOrange":"styles-module__dVj5f7T"};
export { css, digest };
  