import React, { useCallback, useState, useEffect } from 'react';
import { AppState, ConfigAppSDK } from '@contentful/app-sdk';
import { ContentFields, createClient } from 'contentful-management';
import {
  Checkbox,
  Heading,
  HeadingVariant,
  Logo,
  LogoVariant,
  Text,
  TextColor,
  TextSize,
} from '@writerai/component-library';
import { Accordion, AccordionItem } from '@contentful/forma-36-react-components';
import packageJson from '../../../package.json';

import styles from './style.module.scss';

type TModelInfo = ContentFields & {
  isChecked?: boolean;
  modelName: string;
};

export const Config = ({ sdk }: { sdk: ConfigAppSDK }) => {
  const [textFields, setTextFields] = useState<Record<string, TModelInfo[]>>({});

  const onConfigure = useCallback(() => {
    const newConfig: { targetState: AppState } = {
      targetState: {
        EditorInterface: {},
      },
    };

    Object.entries(textFields).forEach(([modelName, modelInfo]) => {
      modelInfo.forEach((field) => {
        if (field.isChecked) {
          if (!newConfig.targetState.EditorInterface[modelName]) {
            newConfig.targetState.EditorInterface[modelName] = {
              controls: [],
            };
          }

          newConfig.targetState.EditorInterface[modelName].controls?.push({
            fieldId: field.id,
          });
        }
      });
    });

    return newConfig;
  }, [textFields]);

  useEffect(() => {
    sdk.app.onConfigure(onConfigure);
  }, [sdk, onConfigure]);

  useEffect(() => {
    (async () => {
      console.info(`${packageJson.name} Version ${packageJson.version}`);
      const { app, cmaAdapter, ids } = sdk;
      const richTextFields: Record<string, TModelInfo[]> = {};
      const activeFields: Record<string, boolean> = {};
      const cma = createClient(
        { apiAdapter: cmaAdapter },
        {
          type: 'plain',
          defaults: {
            environmentId: ids.environmentAlias ?? ids.environment,
            spaceId: ids.space,
          },
        }
      );
      const content = await cma.contentType.getMany({ query: { limit: 1000 } });
      const currentState = await app.getCurrentState();

      if (currentState) {
        Object.entries(currentState.EditorInterface).forEach(
          ([model, value]) => {
            value.controls?.forEach((control) => {
              activeFields[`${model}${control.fieldId}`] = true;
            });
          }
        );
      }

      content.items.forEach((item) => {
        richTextFields[item.sys.id] = [];

        item.fields.forEach((field) => {
          if (
            field.type === "RichText" ||
            field.type === "Text" ||
            field.type === "Symbol"
          ) {
            richTextFields[item.sys.id].push({
              ...field,
              modelName: item.name,
              isChecked: activeFields[`${item.sys.id}${field.id}`] && true,
            });
          }
        });
      });

      setTextFields(richTextFields);

      await app.setReady();
    })();
  }, [sdk]);

  const changeCheckbox = useCallback((id: string, modelId: string) => {
    const newFields = {
      ...textFields,
    };

    newFields[modelId].forEach((field) => {
      if (field.id === id) {
        field.isChecked = !field.isChecked;
      }
    });

    setTextFields(newFields);
  }, [textFields]);

  return (
    <div className={styles.main}>
      <div className={styles.main__bottom}>
        <div className={styles.bottom__form}>
          <div className={styles.form__top}>
            <Heading variant={HeadingVariant.H2}>About Writer AI Content Detector</Heading>
            <Text variant={TextSize.XXXL} color={TextColor.GREY}>
              Writer AI Content Detector evaluates your text in Contentful and provides a score based on how much of it was likely generated by AI.
            </Text>
          </div>
          <div className={styles.form__bottom}>
            <Heading variant={HeadingVariant.H3}>Assign to content types</Heading>
            <Text variant={TextSize.XXXL} color={TextColor.GREY}>Select what content types to use with Writer AI Content Detector.</Text>
            <Accordion className={styles.form__accordion}>
              {Object.entries(textFields).map(([modelId, modelInfo]) => {
                return (
                  <AccordionItem key={modelId} title={modelInfo[0]?.modelName}>
                    {modelInfo.map(({ name, id, isChecked = false }) => {
                      return (
                        <div key={id + modelId} className={styles.main__field}>
                          <Checkbox
                            checked={isChecked}
                            onClick={() => changeCheckbox(id, modelId)}
                          />
                          <Text variant={TextSize.XXXL} color={TextColor.GREY}>{name}</Text>
                        </div>
                      );
                    })}
                  </AccordionItem>
                );
              })}
            </Accordion>
          </div>
        </div>
        <Logo variant={LogoVariant.FULL} className={styles.main__logo} />
      </div>
    </div>
  );
};
