
const digest = '38ee31f41a8478622544eb7fec7bb97dcf2af205247d8544fbb6544318c58bab';
const css = `._header_18kjb_1 {
  display: flex;
  align-items: center;
  padding: 23px 0;
  border-top: 1px solid var(--classic-grey-1);
  border-bottom: 1px solid var(--classic-grey-1);
}

._content_18kjb_9 {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  background-color: var(--writer-blue-1);
  padding: 27px 0;
  border-radius: 8px;
}

._contentWhiteBackground_18kjb_18 {
  background-color: var(--classic-white);
}

._paragraph_18kjb_22 {
  margin: 0 0 13px;
}

._billingBackground_18kjb_26 > svg {
  width: 505px !important;
}

._link_18kjb_30 {
  color: var(--classic-black);
}

._clickable_18kjb_34 {
  cursor: pointer;
  text-decoration: underline;
}

._image_18kjb_39 {
  box-shadow: 0 3.945px 52.596px 0 #acb9dc66;
  border-radius: 14px;
  overflow: hidden;
}

._imageCustom_18kjb_45 {
  box-shadow: none;
}

._title_18kjb_49 {
  margin-left: 16px;
}

._title_18kjb_49 p {
  letter-spacing: 2.8px;
}

._contentTitle_18kjb_57 {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 30px;
  letter-spacing: 3px;
}

._descriptionContainer_18kjb_64 {
  padding-top: 28px;
  color: var(--writer-grey-1);
  max-width: 578px;
  line-height: 28px;
}

._descriptionContainer_18kjb_64 a {
  color: var(--classic-black);
  text-decoration: underline;
}

._learnMore_18kjb_76 {
  color: black;
  margin-bottom: 25px;
}

._learnMore_18kjb_76 a {
  color: black;
}

._lockedSection_18kjb_85 {
  position: absolute;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  padding-top: 34px;
}

._billingLock_18kjb_96 {
  position: relative;
  background: white;
  box-sizing: border-box;
  overflow-y: auto;
  width: 796px;
}

._billingLock_18kjb_96::-webkit-scrollbar-track {
  margin: 5px 0;
}

._actionButton_18kjb_108 {
  margin-left: auto;
  padding: 0 27px 0 10px;
}

._actionButton_18kjb_108 ._withIcon_18kjb_113 {
  padding: 25px 20px 25px 10px;
}
`;

export default {"header":"_header_18kjb_1","content":"_content_18kjb_9","contentWhiteBackground":"_contentWhiteBackground_18kjb_18","paragraph":"_paragraph_18kjb_22","billingBackground":"_billingBackground_18kjb_26","link":"_link_18kjb_30","clickable":"_clickable_18kjb_34","image":"_image_18kjb_39","imageCustom":"_imageCustom_18kjb_45","title":"_title_18kjb_49","contentTitle":"_contentTitle_18kjb_57","descriptionContainer":"_descriptionContainer_18kjb_64","learnMore":"_learnMore_18kjb_76","lockedSection":"_lockedSection_18kjb_85","billingLock":"_billingLock_18kjb_96","actionButton":"_actionButton_18kjb_108","withIcon":"_withIcon_18kjb_113"};
export { css, digest };
  