import { FieldAppSDK } from '@contentful/app-sdk';
import { RichTextEditor } from '@contentful/field-editor-rich-text';
import { SingleLineEditor } from '@contentful/field-editor-single-line';
import { MultipleLineEditor } from '@contentful/field-editor-multiple-line';

const getField = (sdk: FieldAppSDK) => {
  switch (sdk.field.type) {
    case 'RichText':
      return <RichTextEditor sdk={sdk} isInitiallyDisabled={false} />;

    case 'Symbol':
      return <SingleLineEditor field={sdk.field} locales={sdk.locales} isInitiallyDisabled={false} withCharValidation />;

    default:
      return <MultipleLineEditor field={sdk.field} locales={sdk.locales} isInitiallyDisabled={false} withCharValidation />;
  }
};

export default getField;
