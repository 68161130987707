import React, { forwardRef } from 'react';
import ReactDOM from 'react-dom';
import Icon, { IconVariant } from '../Icon';
import Text, { TextSize } from '../typography/Text/Text';

interface IMagicIconProps {
  /** Set additional css class */
  className?: string;
  /** Set inline css styles */
  style?: React.CSSProperties;
  /** Children passed on as React child */
  children?: React.ReactNode;
  onIconClick: () => void;
}

export const MagicLinksIcon = forwardRef<HTMLDivElement, IMagicIconProps>(({ className, style, onIconClick }, ref) => {
  const icon = (
    <div ref={ref} className={className} onClick={onIconClick} style={style}>
      <Icon name={IconVariant.LINK} />
      <Text variant={TextSize.M} medium>
        Find links
      </Text>
    </div>
  );

  return ReactDOM.createPortal(icon, document.body);
});
