import React from 'react';
import cx from 'classnames';
import Icon, { IconVariant } from '../Icon';

import styles from './styles.module.css';

interface CheckboxProps {
  /** Set additional css class */
  className?: string;
  /** Set inline css styles */
  style?: React.CSSProperties;
  /** Children passed on as React child */
  children?: React.ReactNode;
  /** Is checked */
  checked?: boolean;
  /** Is disabled */
  disabled?: boolean;
  /** On toggle callback */
  onClick?: () => void;
}

const CheckedIcon = () => (
  <div className={styles.checkedIconContainer}>
    <Icon name={IconVariant.BLUE_CHECKMARK} width={18} height={18} />
  </div>
);

const DisableIcon = () => (
  <div className={styles.uncheckedIconContainer}>
    <Icon name={IconVariant.GREY_CHECKMARK} width={18} height={18} />
  </div>
);

export const Checkbox: React.FC<CheckboxProps> = ({ className, children, checked, disabled, onClick, ...props }) => {
  const elClass: string = cx(styles.container, className, { [styles.disabled]: disabled });

  return (
    <label className={elClass}>
      {children && <div>{children}</div>}
      <input type="checkbox" checked={checked} onChange={onClick} {...props} />
      {checked && !disabled && <CheckedIcon />}
      {disabled && !checked && <DisableIcon />}
      <span className={styles.checkmark} />
    </label>
  );
};
