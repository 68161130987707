import type { ReactNode } from 'react';
import type { ITermUser } from './User';

export enum TermType {
  APPROVED = 'approved',
  USE_CAREFULLY = 'useCarefully',
  BANNED = 'banned',
  PENDING = 'pending',
}

export enum PartOfSpeech {
  DEFAULT = '-',
  NOUN = 'noun',
  ADJECTIVE = 'adjective',
  VERB = 'verb',
  ADVERB = 'adverb',
}

export enum TermExampleType {
  Good = 'good',
  Bad = 'bad',
}

export enum CaseSensitiveOptions {
  YES = 'yes',
  NO = 'no',
}

export interface ITermExample extends ITermExampleBase {
  id: number;
  termId: number;
}

export interface ITermMistake extends ITermMistakeBase {
  id: number;
  termId: number;
}

export interface ILinkedTermBE {
  reference: string;
}

export interface ILinkedTerm {
  id: number;
  termId: ITerm['id'];
  linkedTermId: ITerm['id'];
  type: ITerm['type'];
  term: ITerm['term'];
  pos?: ITerm['pos'];
}

export interface ITermTag {
  id: number;
  tag: string;
  termId: number;
  parentTagId: number;
}

export interface ITermBaseProps {
  id: number;
  description: string;
  creationTime: string;
  modificationTime: string;
  createdUser: ITermUser;
  modifiedUser: ITermUser;
  tags: ITermTag[];
}

export interface IApprovedTermExtension {
  capitalize: boolean;
  fixCommonMistakes: boolean;
  fixCase: boolean;
}

export interface ITerm extends ITermBaseProps {
  termBankId: number;
  term: string;
  type: string;
  pos: PartOfSpeech;
  caseSensitive: boolean;
  examples: ITermExample[];
  mistakes: ITermMistake[];
  modificationPersonTime: string;
  highlight: boolean;
  linkedTerms: ILinkedTerm[];
  backlinkedTerms: ILinkedTerm[];
  approvedTermExtension?: IApprovedTermExtension;
}

export interface ICreateTerm {
  models: ITerm[];
}

export interface TableColumnTerm extends ITerm {
  modificationInfo?: string;
}

export interface ITermsTagsListResponse {
  tags: string[];
}

export type ITermMistakeBaseProps = {
  isValid: boolean;
  validationMessage: string;
};

export interface ITermExampleBase {
  example: string;
  type: TermExampleType;
  _error?: string;
}

export interface ITermMistakeBase {
  mistake: string;
  caseSensitive: boolean;
  pos?: PartOfSpeech;
  _props?: ITermMistakeBaseProps;
}

export interface CommonMistakeDataItem extends ITermMistakeBase {
  id: number;
  pos?: PartOfSpeech;
  _error?: string;
}

export interface ITermCreateAndUpdate {
  id?: number;
  reference?: string;
  pos?: PartOfSpeech;
  tags: ITermTag[];
  term: string;
  type: string;
  caseSensitive: boolean;
  highlight: boolean;
  backlinkedTerms: ILinkedTerm[];
  description?: string;
  approvedTermExtension?: IApprovedTermExtension;
  examples: ITermExampleBase[];
  mistakes: ITermMistakeBase[];
  linkedTerms: ILinkedTermBE[];
}

export interface ICreateTermsRequest {
  models: ITermCreateAndUpdate[];
}

export interface ITermImportFail {
  description: string;
  extras: {
    conflict: {
      linkType: string;
      reference?: string | null;
      term: string;
    };
    item: {
      linkType: string;
      reference?: string | null;
      term: string;
    };
  };
  key: string;
}

export interface ICreateTermsWithFails {
  models: ITerm[];
  fails: ITermImportFail[];
}

export interface MultiFieldError {
  message: string;
  term?: string;
  fieldPosition?: number;
}

export enum TermsPopupFields {
  TERM,
  TERM_TYPE,
  POS,
  CASE_SENSITIVE,
  DESCRIPTION,
  EXAMPLE,
  COMMON_MISTAKE,
  TAGS,
  ENABLE_SUGGESTIONS,
  CAPITALIZE,
  SUGGESTED_APPROVED_TERMS,
  FIX_CASE,
  FIX_COMMON_MISTAKES,
}

export const initMultiFieldError: MultiFieldError = {
  message: '',
  term: '',
  fieldPosition: -1,
};

/* Todo: this should extend from ITerm or ITermCreateAndUpdate. Find the diff */
export interface TAddTermsState {
  id: number;
  term: string;
  termType?: TermType;
  partOfSpeech: PartOfSpeech;
  caseSensitive: boolean;
  description: string;
  example: ITermExampleBase[];
  commonMistake: CommonMistakeDataItem[];
  tags: ITermTag[];
  isLoading?: boolean;
  termError?: string;
  descriptionError?: string;
  examplesError?: MultiFieldError;
  mistakesErrors: MultiFieldError[];
  tagsError?: string;
  enableSuggestions: boolean;
  capitalize: boolean;
  fixCase: boolean;
  fixCommonMistakes: boolean;
  linkedTerms: ILinkedTerm[];
  backlinkedTerms: ILinkedTerm[];
}

export const initAddTermState: TAddTermsState = {
  id: 0,
  term: '',
  termType: TermType.APPROVED,
  partOfSpeech: PartOfSpeech.DEFAULT,
  caseSensitive: true,
  description: '',
  example: [],
  commonMistake: [],
  tags: [],
  isLoading: false,
  termError: '',
  descriptionError: '',
  examplesError: initMultiFieldError,
  mistakesErrors: [],
  tagsError: '',
  enableSuggestions: true,
  capitalize: true,
  fixCase: true,
  fixCommonMistakes: true,
  linkedTerms: [],
  backlinkedTerms: [],
};

export const suggestionsDefaultTooltips = {
  banned: `When checking content, we'll underline don't use terms in yellow.`,
  enableApprovedTerm: `When Writer finds the approved term in your content, we'll highlight the term in blue and show a definition card on hover.`,
  enableCommonMistakeTerm: `When Writer finds a common mistake in your content, we'll underline it in yellow and show a suggestion on hover.`,
  correctCaps: `If Writer finds this term in any other casing combination, we'll correct it to the specific casing you've written here.`,
  capsAtStart: `If Writer finds this term at the start of a sentence and uncapitalized, we'll suggest that you capitalize it.`,
  suggestedApprovedTerms: `Select an approved term that you'd like your team to use instead. The approved term will appear as a suggested replacement when we find the don't use term in your content. You can select up to three.`,
  disabledSuggestionsTooltip: `Add a description to your term to enable approved term highlighting.`,
};

/**
 * List of possible BE errors
 *
 * fail.termBank.duplicate=Term Bank with same name already exists.
 * fail.termBank.delete.linked=This Term Bank can't be deleted because it's linked to projects.
 * fail.term.validation.banned.examples=Don't use term can't have examples or mistakes.
 * fail.term.duplicate=This term already exists
 * fail.term.name.nonEmpty=this is a required field
 * fail.term.name.too_long=max length is 256 characters
 * fail.term.intersect.mistake=already exists as a term
 * fail.term.mistake.duplicate=already exists as a common mistake
 * fail.term.mistake.intersect.term=already exists as a term
 * fail.snippet.duplicate=Some shortcuts already exist.
 * fail.snippet.title.duplicate=Some titles already exist.
 * fail.snippet.validation.tags.duplicate=Some snippets have duplicated tags
 * fail.term.validation.tags.duplicate=Some terms have duplicated tags
 * fail.input.validator.security.xss=symbols like ''&lt;'' are not supported
 * fail.badRequest.validator.length.max=must be {1} characters or fewer
 * fail.badRequest.validator.length.min=must be {1} characters or more
 *
 */
export const TermErrorKeys = {
  DUPLICATE_TERM: 'fail.term.duplicate',
  DUPLICATE_SNIPPET_TITLE: 'fail.snippet.title.duplicate',
  DUPLICATE_SNIPPET: 'fail.snippet.duplicate',
  DUPLICATE_AS_TERM: 'fail.term.intersect.mistake',
  DUPLICATE_COMMON_MISTAKE_AS_TERM: 'fail.term.mistake.intersect.term',
  DUPLICATE_COMMON_MISTAKE: 'fail.term.mistake.duplicate',
  DUPLICATE_TAGS: 'fail.term.validation.tags.duplicate',
  DUPLICATE_SNIPPET_TAGS: 'fail.snippet.validation.tags.duplicate',
  XSS_INJECTION: 'fail.input.validator.security.xss',
  MAX_LENGTH: 'fail.badRequest.validator.length.max',
  MIN_LENGTH: 'fail.badRequest.validator.length.min',
  LONG_TERM: 'fail.term.name.too_long',
  EMPTY_TERM_NAME: 'fail.term.name.nonEmpty',
  TERM_BANNED_CANT_HAVE_EXAMPLES_MISTAKES: 'fail.term.validation.banned.examples',
  TERM_BANK_LINKED: 'fail.termBank.delete.linked',
  TERM_BANK_DUPLICATE: 'fail.termBank.duplicate',
  TERM_BANK_SNIPPET_REGEX: 'fail.input.validator.snippet.shortcut.regex',
  SNIPPET_SHORTCUT: 'shortcut',
  SNIPPET_DESCRIPTION: 'description',
  SNIPPET_SNIPPET: 'snippet',
  SNIPPET_TAGS: 'tags',
};

export const createTermMapper = (state: TAddTermsState): ITermCreateAndUpdate => ({
  id: state.id,
  term: state.term.trim(),
  type: state.termType as any,
  caseSensitive: state.caseSensitive,
  tags: state.tags.map(d => ({ ...d, tag: d.tag.trim() } as ITermTag)),
  // remove empty description
  ...(state.description?.length && { description: state.description }),
  // remove pos=PartOfSpeech.DEFAULT
  ...(state.partOfSpeech !== PartOfSpeech.DEFAULT && { pos: state.partOfSpeech }),
  // filter objects with empty `example`
  examples: state.example
    .filter(d => !!d.example.length)
    .map(d => ({ ...d, example: d.example.trim() } as ITermExampleBase)),
  // filter objects with empty `mistake` & empty pos:'-' values
  mistakes: state.commonMistake
    .map(({ mistake, pos, caseSensitive }, i) =>
      pos !== PartOfSpeech.DEFAULT ? state.commonMistake[i] : ({ mistake, caseSensitive } as ITermMistakeBase),
    )
    .map(d => ({ ...d, mistake: d.mistake.trim() } as ITermMistakeBase))
    .filter(d => !!d.mistake.length),
  highlight: state.enableSuggestions,
  approvedTermExtension: {
    fixCase: state.fixCase === true,
    fixCommonMistakes: state.fixCommonMistakes === true,
    capitalize: state.capitalize === true,
  },
  // when update\create term, we need to prefix linked term with:
  // - # if we refer to ID of a term
  // - @ if we refer to reference (used in terms upload)
  // - no-prefix if we refer to the term itself
  linkedTerms: state.linkedTerms.map(term => ({ reference: `#${term.linkedTermId.toString()}` })) as ILinkedTermBE[],
  backlinkedTerms: [],
});

export const termToAddTermsStateMapper = (data: ITerm): TAddTermsState => ({
  id: data.id,
  term: data.term,
  termType: data.type as TermType,
  caseSensitive: data.caseSensitive,
  tags: data.tags,
  description: data.description,
  partOfSpeech: data.pos || PartOfSpeech.DEFAULT,
  example: data.examples as ITermExampleBase[],
  commonMistake: data.mistakes,
  enableSuggestions: data.highlight === true,
  fixCase: data.approvedTermExtension?.fixCase === true,
  fixCommonMistakes: data.approvedTermExtension?.fixCommonMistakes === true,
  capitalize: data.approvedTermExtension?.capitalize === true,
  linkedTerms: data.linkedTerms,
  backlinkedTerms: data.backlinkedTerms,
  mistakesErrors: [],
});

export const termToLinkedTerm = (termId: number, linkedTerm: ITerm): ILinkedTerm => ({
  id: linkedTerm.id,
  termId,
  type: linkedTerm.type,
  linkedTermId: linkedTerm.id,
  term: linkedTerm.term,
  pos: linkedTerm.pos,
});

export interface ITableFilter {
  id: string;
  name: string;
  isSelected: boolean;
  tagColor?: string;
  icon?: ReactNode;
}
