/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import { isUndefined } from 'lodash/fp';
import { getDateFormatMMDDYYYY, hashCode, stopEventPropagation, TermType } from '@writerai/common-utils';
import { Cell, Column, HeaderGroup, Row, useRowSelect, useTable } from 'react-table';
import {
  MuiTable,
  MuiTableBody,
  MuiTableCell,
  MuiTableContainer,
  MuiTableHead,
  MuiTableRow,
  Text,
  TextColor,
  TextSize,
  DotLoader,
  ExtraDetailRow,
  Tag,
  TagColor,
  Checkbox,
} from '@writerai/ui-atoms';

import styles from './styles.module.css';

export interface IBaseTable<D> {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  tableData: D[];
  tableColumns: ITableColumnItem[];
  containerClassName?: string;
  tableRowClassName?: string;
  tableCellClassName?: string;
  emptyTableContent: React.ReactNode;
  isLoading?: boolean;
  searchTerm?: string;
  onRowClick?: (rowData) => void;
  onMultiSelect?: (rowData) => void;
  disableOperations?: boolean;
  tableFooter?: JSX.Element;
  multiSelectAllMonitor?: boolean;
  selectedOriginalId?: number;
  isCheckboxHidden?: boolean;
}

export interface ITableColumnItem {
  [name: string]: any;
}

export interface RenderAsTextProps {
  size?: TextSize;
  className?: string;
  tooltipText?: string;
  key?: string;
}

export const renderAsText = (text, props?: RenderAsTextProps) => {
  const textSize = props && props.size ? props.size : TextSize.S;
  const className = props && props.className ? props.className : styles.baseTableCellDefaultText;
  const key = props && props.key ? props.key : hashCode(text);

  return (
    <>
      {text && (
        <Text variant={textSize} className={className} key={key}>
          {text}
        </Text>
      )}
    </>
  );
};
export const renderAsTextDate = (text: string | Date | number, props?: RenderAsTextProps) => {
  const textSize = props && props.size ? props.size : TextSize.XS;

  return (
    <Text variant={textSize} {...props}>
      {getDateFormatMMDDYYYY(text)}
    </Text>
  );
};

export const renderAsLabel = (text: string, color: TagColor = TagColor.GREY) => (
  <Tag className={cx(styles.tableCellLabel)} color={color}>
    <Text extraSmallCaps color={TextColor.BLACK} variant={TextSize.XXXS}>
      {text}
    </Text>
  </Tag>
);

export const renderAsTag = (text: string, color: TagColor = TagColor.LIGHT_BLUE, key: string) => (
  <Tag className={cx(styles.tableCellTag)} color={color} key={key}>
    <Text variant={TextSize.XS} color={TextColor.BLACK}>
      {text}
    </Text>
  </Tag>
);

export const renderTermType = (type: TermType) => {
  switch (type) {
    case TermType.BANNED:
      return renderAsLabel("Don't use", TagColor.LIGHT_PEACH);
    case TermType.PENDING:
      return renderAsLabel('Pending', TagColor.LIGHT_ORANGE);
    case TermType.APPROVED:
      return renderAsLabel('Approved', TagColor.LIGHT_GREEN);
    case TermType.USE_CAREFULLY:
      return renderAsLabel('Use carefully', TagColor.YELLOW_1);
    default:
      return null;
  }
};

const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, any>(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef: any = ref || defaultRef;

  React.useEffect(() => {
    if (!isUndefined(indeterminate)) {
      resolvedRef.current.indeterminate = indeterminate;
    }
  }, [resolvedRef, indeterminate]);

  return (
    <div ref={resolvedRef}>
      <Checkbox {...rest} />
    </div>
  );
});

const isTextMatched = (cellValue, searchTerm: string) => {
  let _str;

  if (cellValue) {
    if (typeof cellValue === 'string' || typeof cellValue === 'number') {
      _str = `${cellValue}`;
    } else if (cellValue?.props && Object.prototype.hasOwnProperty.call(cellValue.props, 'data-value')) {
      _str = cellValue.props['data-value'];
    }
  }

  if (Array.isArray(cellValue) && cellValue.length) {
    // todo: special case added to scan through tags and common mistakes. make it smarter
    if (cellValue[0].tag) {
      _str = cellValue.map(({ tag }) => tag).join(',');
    }

    if (cellValue[0].mistake) {
      _str = cellValue.map(({ mistake }) => mistake).join(',');
    }
  }

  return (
    _str
      ?.toString()
      .toLowerCase()
      .indexOf((searchTerm || '').toLowerCase()) > -1
  );
};

export const BaseTable = <D, T>({
  className,
  containerClassName,
  style,
  tableColumns,
  tableData,
  tableCellClassName,
  tableRowClassName,
  isLoading,
  searchTerm,
  onRowClick,
  onMultiSelect,
  disableOperations,
  tableFooter,
  multiSelectAllMonitor,
  children,
  selectedOriginalId,
  isCheckboxHidden,
}: IBaseTable<D>) => {
  const selectedFlatRowsRef = useRef([] as any);
  const { getTableProps, headerGroups, rows, prepareRow, selectedFlatRows, toggleAllRowsSelected } = useTable(
    {
      columns: tableColumns as Column<object>[], // Todo: fix the type inference here
      data: tableData as any,
    },
    useRowSelect,
    hooks =>
      isCheckboxHidden
        ? hooks.visibleColumns
        : hooks.visibleColumns.push(columns => [
            {
              id: 'selection',

              // The cell can use the individual row's getToggleRowSelectedProps method to the render a checkbox
              Cell: ({ row }) => (
                <div className={styles.checkbox} onClick={stopEventPropagation}>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ]),
  );

  const mappedTermRows = (row: Row<object>) => {
    prepareRow(row);

    const onClick = e => {
      if (e.shiftKey || e.target.type === 'checkbox') {
        row.toggleRowSelected();

        return;
      }

      if (e.target.classList.contains(styles.firstCell)) return;

      if (e.target) !disableOperations && onRowClick && onRowClick(row.original!);
    };

    return (
      <MuiTableRow
        {...row.getRowProps()}
        data-selected={row.isSelected || row.original.id === selectedOriginalId}
        className={cx(styles.tableRow, tableRowClassName, {
          [styles.selectedRow]: row.isSelected,
          [styles.rowDisabled]: disableOperations,
        })}
        onClick={onClick}
      >
        {row.cells.map((cell: Cell<object, any>, i) => (
          <MuiTableCell
            {...cell.getCellProps()}
            className={cx(styles.tableCell, tableCellClassName, cell.className, cell.column.cellClassName, {
              [styles.matchFound]:
                searchTerm && !cell.column.disableMatchFound && isTextMatched(cell.value, searchTerm),
              [styles.firstCell]: i === 0,
            })}
            colSpan={(cell as any)?.column?.colSpan || 1}
          >
            {cell.render('Cell')}
          </MuiTableCell>
        ))}
      </MuiTableRow>
    );
  };

  const renderTableSpinner = () => (
    <MuiTableBody>
      <ExtraDetailRow colspan={8}>
        <DotLoader style={{ marginTop: 50 }} />
      </ExtraDetailRow>
    </MuiTableBody>
  );

  const renderTableContent = () => (
    <>
      {isLoading ? renderTableSpinner() : <MuiTableBody>{rows.length ? rows.map(mappedTermRows) : null}</MuiTableBody>}
    </>
  );

  const renderTableHeader = () => (
    <MuiTableHead className={cx(styles.header)}>
      {headerGroups.map(headerGroup => (
        <MuiTableRow {...headerGroup.getHeaderGroupProps()} className={styles.tableRow}>
          {headerGroup.headers.map((column: HeaderGroup) => (
            <MuiTableCell
              {...column.getHeaderProps()}
              key={column.id}
              className={cx(styles.headerColumn, column.className)}
              colSpan={(column as any)?.colSpan || 1}
            >
              {column.render('Header')}
            </MuiTableCell>
          ))}
        </MuiTableRow>
      ))}
    </MuiTableHead>
  );

  useEffect(() => {
    if (selectedFlatRowsRef.current.length !== selectedFlatRows.length) {
      const mapOfOriginalItems = selectedFlatRows.map(d => d.original);
      onMultiSelect && onMultiSelect(mapOfOriginalItems);
      selectedFlatRowsRef.current = selectedFlatRows;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlatRows]);

  useEffect(() => {
    toggleAllRowsSelected(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiSelectAllMonitor]);

  const TableFooter = () => (tableFooter ? <div className={styles.footerContainer}>{tableFooter}</div> : null);

  return (
    <div className={cx(styles.container, containerClassName)} style={style}>
      <MuiTableContainer className={styles.tableContainer}>
        <MuiTable {...getTableProps()} className={cx(styles.baseTable, className)} stickyHeader>
          {renderTableHeader()}
          {renderTableContent()}
        </MuiTable>
        {!isLoading && <TableFooter />}
      </MuiTableContainer>
      {children}
    </div>
  );
};
export default React.memo(BaseTable);
