
const digest = '8db79019c161a593add02c39c3c20327f687a6c6fd79f5f19678c74e8b2f08c3';
const css = `.styles-module__kUnnPkX {
  position: relative;
  display: block;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
}

.styles-module__kUnnPkX input {
  position: absolute;
  width: 0;
  height: 0;
  cursor: pointer;
  opacity: 0;
}

.styles-module__dJ8SrbK {
  position: absolute;
  top: 1px;
  left: 0;
  width: 18px;
  height: 18px;
  border: 1px solid var(--classic-grey-2);
  border-radius: 4px;
  background: var(--classic-white);
}

.styles-module__dJ8SrbK:hover {
  border: 1px solid var(--classic-grey-3);
}

.styles-module__3UeKfVy {
  color: var(--writer-grey-1);
  pointer-events: none;
}

.styles-module__3UeKfVy.styles-module__kUnnPkX .styles-module__dJ8SrbK {
  background-color: var(--classic-white);
  opacity: 0.4;
}

.styles-module__kUnnPkX input:checked ~ .styles-module__dJ8SrbK::after {
  top: 1px;
  left: 5px;
  display: block;
  width: 4px;
  height: 9px;
  box-sizing: content-box;
  border: solid var(--classic-white) !important;
  border-width: 0 1px 1px 0 !important;
  transform: rotate(45deg);
}

.styles-module__3UeKfVy.styles-module__kUnnPkX input:checked ~ .styles-module__dJ8SrbK::after {
  border: solid var(--classic-grey-1-5) !important;
  border-width: 0 1px 1px 0 !important;
}

.styles-module__3UeKfVy.styles-module__kUnnPkX .styles-module__dJ8SrbK::after {
  top: 2px;
  left: 6px;
  border: solid white;
}

.styles-module__3UeKfVy.styles-module__kUnnPkX input:checked ~ .styles-module__dJ8SrbK {
  opacity: 1 !important;
}

.styles-module__3UeKfVy.styles-module__kUnnPkX input:checked::after ~ .styles-module__dJ8SrbK {
  display: block;
  border: solid var(--classic-grey-1-5) !important;
  border-width: 0 1px 1px 0 !important;
}

.styles-module__kUnnPkX:hover input ~ .styles-module__dJ8SrbK {
  background-color: var(--classic-white);
}

.styles-module__kUnnPkX:not(.styles-module__3UeKfVy) input:checked ~ .styles-module__dJ8SrbK {
  border: 1px solid transparent;
  background-color: transparent;
}

.styles-module__dJ8SrbK::after {
  position: absolute;
  display: none;
  content: '';
}

.styles-module__dJ8SrbK:hover::after {
  display: block;
  border: solid var(--classic-grey-2) !important;
  border-width: 0 1px 1px 0 !important;
}

.styles-module__kUnnPkX input:checked:hover ~ .styles-module__dJ8SrbK::after {
  border: solid white !important;
  border-width: 0 1px 1px 0 !important;
}

.styles-module__kUnnPkX .styles-module__dJ8SrbK::after {
  top: 1px;
  left: 5px;
  width: 4px;
  height: 10px;
  box-sizing: content-box;
  border: solid white;
  border-width: 0 1px 1px 0;
  transform: rotate(36deg);
}

.styles-module__mYyXGJj {
  position: absolute;
  z-index: 1;
  top: -3px;
  left: 0;
}

.styles-module__zf2xNYE {
  position: absolute;
  z-index: 1;
  top: -4px;
  left: 0;
}
`;

export default {"container":"styles-module__kUnnPkX","checkmark":"styles-module__dJ8SrbK","disabled":"styles-module__3UeKfVy","checkedIconContainer":"styles-module__mYyXGJj","uncheckedIconContainer":"styles-module__zf2xNYE"};
export { css, digest };
  