
const digest = '05f5bbdb914ac72b12abe6011dc97b10f7786ae0030c324bdd9ed95a4f01696f';
const css = `.styles-module__Va541sK {
  word-break: break-word;
}

.styles-module__Oe4Y104 {
  position: absolute;
  left: 0;
  width: 100%;
  height: 26px;
}
`;

export default {"correctionLabel":"styles-module__Va541sK","correctionLabelShadow":"styles-module__Oe4Y104"};
export { css, digest };
  