import trim from 'lodash/trim';
import type { ReactNode } from 'react';
import type { ITableFilter } from '../types/Terms';

/**
 * A no-operation dummy function
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

/**
 * Promisified a no-operation dummy function
 */
export const promisedNoop = () => Promise.resolve();

/**
 * Adds a delay in execution
 * @param ms - milliseconds
 * @return {Promise<unknown>}
 */
export const delay = (ms: number): Promise<unknown> => new Promise(res => setTimeout(res, ms));

export const emailAddressCleanup = (email: string): string => trim(email).split(' ').join('+');

export const toSeconds = (milliSeconds: number): number => Math.round(milliSeconds / 1000);
export const datesDifSeconds = (first: number, last: number): number => toSeconds(first - last);

export const daysDiff = (fromDate, toDate): number =>
  Math.floor((Date.parse(fromDate) - Date.parse(toDate)) / 86400000); // 86400000 ms in 1 day

// eslint-disable-next-line @typescript-eslint/ban-types
export const retry = <T>(operation: Function, delayTime: number, retries: number): Promise<T> =>
  new Promise((resolve, reject) =>
    operation()
      .then(resolve)
      .catch(reason => {
        if (retries > 0) {
          return delay(delayTime)
            .then(retry.bind(null, operation, delayTime, retries - 1))
            .then(r => resolve(r as T))
            .catch(reject);
        }

        return reject(reason);
      }),
  );

/**
 * JSX Function to switch between JSX tags
 * Eg:
 *  {rswitch(type, {
 *     'suggestion': <Suggestion />,
 *          default: <div><div/>,
 *  })}
 * @param param: the key which will switch between cases
 * @param cases: Object with cases including default
 * @returns {any}
 */
export const rswitch = (param, cases) => {
  if (cases[param]) {
    return cases[param];
  }

  return cases.default;
};

interface IFilterTag {
  id: string;
  name: string;
  tagColor?: string;
  icon?: ReactNode;
}

/**
 * Transform tag strings into ITableFilter array
 * @param tags string[]
 * @returns {ITableFilter[]}
 */
export const toTagFilters = (tags: string[]): ITableFilter[] =>
  tags.map(tag => ({ id: tag, name: tag, isSelected: false }));

/**
 * Transform tag strings into ITableFilter array
 * @param tags string[]
 * @returns {ITableFilter[]}
 */
export const toTagFiltersFromObject = (tags: IFilterTag[]): ITableFilter[] =>
  tags.map(({ id, name, tagColor, icon }) => ({ id, name, isSelected: false, tagColor, icon }));

export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue =>
  value !== null && value !== undefined;

export const loadScript = (url: string, async = false) => {
  const script = document.createElement('script');
  script.src = url;

  if (async) {
    script.async = true;
  }

  document.body.appendChild(script);
};
