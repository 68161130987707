
const digest = '00a4af641a95a821128161da9f94e6564f3d5ecb119ea4965fafe54f764e17f6';
const css = `.styles-module__jbMa62n {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.styles-module__LxIPciX {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--classic-grey-1);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.styles-module__LxIPciX:hover {
  background-color: var(--writer-graynu-2);
}
`;

export default {"container":"styles-module__jbMa62n","background":"styles-module__LxIPciX"};
export { css, digest };
  