
const digest = 'ee35027ce3778ccff89fe9f8f38e1217649f12a5113e547cc1670c838ad803c3';
const css = `.styles-module__J8hYE8S {
  z-index: 0;
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 10px;
  cursor: pointer;
  user-select: none;
}

.styles-module__lmgz1Ff {
  margin-left: 6px;
  color: var(--classic-grey-2);
  cursor: pointer;
}

.styles-module__J8hYE8S:hover .styles-module__lmgz1Ff {
  color: var(--black);
}

.styles-module__J8hYE8S i {
  position: relative;
  display: flex;
  width: 26px;
  height: 26px;
  align-items: center;
}

.styles-module__J8hYE8S i svg {
  position: relative;
  z-index: 1;
}

.styles-module__J8hYE8S:hover i::before {
  position: absolute;
  z-index: 1;
  left: -3px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: var(--writer-blue-2);
  content: '';
}
`;

export default {"addRowButton":"styles-module__J8hYE8S","addRowButtonText":"styles-module__lmgz1Ff"};
export { css, digest };
  