
const digest = '2c6fab1967811cc33ad6c5c09025607bd07e9265ebca917233a991a420c4b580';
const css = `.styles-module__36ZtFti {
  max-height: 100%;
  padding: 30px 37px;
  overflow-y: auto;
}

.styles-module__cFa1E4B {
  width: 250px;
  margin: 0;
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
}

.styles-module__s1U8AVv {
  margin: 0;
  font-size: 16px;
  line-height: 28px;
}

.styles-module__WhYwUB- {
  margin-bottom: 30px;
}

.styles-module__zhFqbV8 {
  padding: 25px;
}

.styles-module__eAKyHTf {
  margin: 14px 0 0 28px;
}

.styles-module__RazdnIV {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}
`;

export default {"container":"styles-module__36ZtFti","header":"styles-module__cFa1E4B","description":"styles-module__s1U8AVv","withBottomMargin":"styles-module__WhYwUB-","exampleImg":"styles-module__zhFqbV8","exampleBText":"styles-module__eAKyHTf","exampleHeader":"styles-module__RazdnIV"};
export { css, digest };
  