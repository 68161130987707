import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

import usePaginator from '../../hooks/usePaginator';

export interface PaginationProps {
  className?: string;
  onChange?: (event, number: number) => void;
  siblingCount?: number;
  total: number;
  offset: number;
  limit: number;
}

export const MIN_PAGES_COUNT_FOR_PAGINATION = 1;
export const MIN_PAGES_COUNT_FOR_PAGINATION_FAST_NAVIGATION = 10;

export const Paginator: React.FC<PaginationProps> = ({
  className,
  onChange,
  siblingCount = 5,
  total,
  limit,
  offset,
}) => {
  const [isPaginationReady, setIsPaginationReady] = useState(false);
  const [pagesAmount, setPagesAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFastNavigation, setShowFastNavigation] = useState(false);

  useEffect(() => {
    const _pagesAmount = Math.ceil(total / limit) || 1;
    setCurrentPage(parseInt(`${offset / limit + 1}`, 10));
    setPagesAmount(_pagesAmount);
    setShowFastNavigation(_pagesAmount > MIN_PAGES_COUNT_FOR_PAGINATION_FAST_NAVIGATION);
    setIsPaginationReady(_pagesAmount > MIN_PAGES_COUNT_FOR_PAGINATION);
  }, [total, limit, offset]);

  const paginator = usePaginator({
    className: styles.container,
    page: currentPage,
    count: pagesAmount,
    onChange,
    siblingCount,
    showFirstButton: showFastNavigation,
    showLastButton: showFastNavigation,
    pageClassname: styles.containerPaginatorPage,
    containerPaginatorPageActive: styles.containerPaginatorPageActive,
    paginatorButtonClassName: styles.paginatorButton,
    paginatorLastButtonClassName: styles.paginatorButtonLastPage,
    paginatorPrevButtonClassName: styles.paginatorButtonPrevPage,
    paginatorNextButtonClassName: styles.paginatorButtonNextPage,
    paginatorFirstButtonClassName: styles.paginatorButtonFirstPage,
    paginatorPlaceholderLeftClassName: styles.paginatorPlaceholderLeft,
    paginatorPlaceholderRightClassName: styles.paginatorPlaceholderRight,
  });

  return <div className={cx(styles.container, className)}>{isPaginationReady && paginator}</div>;
};

export default Paginator;
