
const digest = '608d9d6eaf5413542286e8fde8f1a226eeff4c32a8b8717cf222d07be0684dc5';
const css = `.styles-module__TkNU1g9 {
  display: block;
}

.styles-module__QDknpJA {
  display: flex;
}
`;

export default {"container":"styles-module__TkNU1g9","wrapper":"styles-module__QDknpJA"};
export { css, digest };
  