import React from 'react';
import cx from 'classnames';
import { extractFirstLetters, AVATAR_COLOR } from '@writerai/common-utils';

import styles from './styles.module.css';

import { Text, TextSize } from '../typography/Text/Text';
import DefaultUserAvatar from '../../assets/icons/defaultUserAvatar.svg';

export interface IUserAvatarProps {
  /** Set additional css class */
  className?: string;
  /** Set inline css styles */
  style?: React.CSSProperties;
  /** Children passed on as React child */
  children?: React.ReactNode;
  /** Set text inside the icon, top priority variable will override fullName & avatarPath (3 character limit)  */
  label?: string;
  /** Set user initials inside the color icon (will be used to form initials) */
  fullName?: string;
  /** Set abbreviation (for Team) inside the color icon */
  abbreviation?: string;
  /** Set css class name for user abbreviation */
  abbreviationClassName?: string;
  /** Set user picture path */
  avatarPath?: string;
  /** Set size of the icon */
  size?: AvatarSize;
  /** Set color of the icon when image does not exist */
  color?: AVATAR_COLOR;
  customColor?: string;
  onClick?: () => void;
}

export enum AvatarSize {
  L = 'l',
  M = 'm',
  XS = 'xs',
  XL = 'xl',
  XXXL = 'xxxl',
}

const generateColor = (text): string => {
  const colorsPlate = Object.values(AVATAR_COLOR);
  const value = text;
  const valueLength = value?.length || 0;

  return colorsPlate[valueLength % colorsPlate.length];
};

export const UserAvatar = React.forwardRef<HTMLDivElement, IUserAvatarProps>(
  (
    {
      size = AvatarSize.XS,
      color,
      customColor,
      className,
      abbreviationClassName,
      label,
      fullName,
      abbreviation,
      avatarPath,
      style,
      onClick,
      ...props
    },
    ref,
  ) => {
    const avatarColor = customColor || color || generateColor(fullName || label);
    const elClass: string = cx(styles.container, className, {
      [styles.sizeXs]: AvatarSize.XS === size,
      [styles.sizeXl]: AvatarSize.XL === size,
      [styles.sizeXl]: AvatarSize.XL === size,
      [styles.sizeXxxl]: AvatarSize.XXXL === size,
      [styles.sizeL]: AvatarSize.L === size,
      [styles.sizeM]: AvatarSize.M === size,
    });
    const inlineStyles = { ...style, ...(!avatarPath ? { background: avatarColor } : {}) };

    const renderIcon = () => {
      const textVariant = size === AvatarSize.XS ? TextSize.XS : TextSize.L;
      let icon;

      if (label && label.length < 4) {
        icon = (
          <Text variant={textVariant} bold upperCase className={elClass}>
            {label}
          </Text>
        );
      } else if (avatarPath) {
        icon = <img src={avatarPath} alt="" className={elClass} />;
      } else if (fullName) {
        icon = (
          <Text variant={textVariant} bold upperCase className={cx(elClass, abbreviationClassName)}>
            {extractFirstLetters(fullName).slice(0, 2)}
          </Text>
        );
      } else if (abbreviation) {
        icon = (
          <Text variant={textVariant} bold className={cx(elClass, abbreviationClassName)}>
            {abbreviation.slice(0, 2)}
          </Text>
        );
      } else {
        icon = (
          <div className={cx(elClass, styles.default)}>
            <DefaultUserAvatar />
          </div>
        );
      }

      return icon;
    };

    return (
      <div className={elClass} {...props} style={inlineStyles} ref={ref} onClick={onClick}>
        <div className={styles.containerInitials}>{renderIcon()}</div>
      </div>
    );
  },
);

export default UserAvatar;
