import React from 'react';
import {
  MuiAccordion,
  MuiAccordionSummary,
  MuiAccordionDetails,
  MuiAccordionProps,
  muiUtils,
  TextSize,
  Text,
  Icon,
  IconVariant,
} from '@writerai/ui-atoms';

const Accordion = muiUtils.withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = muiUtils.withStyles({
  root: {
    borderBottom: 'none',
    padding: 0,
    minHeight: 'auto !important',
    justifyContent: 'flex-start',
    marginBottom: -1,
  },
  content: {
    margin: '10px 5px 10px 0 !important',
    flexGrow: 0,
    '& p': {
      fontWeight: 500,
    },
  },
  expandIcon: {
    padding: 0,
  },
})(MuiAccordionSummary);

const AccordionDetails = muiUtils.withStyles({
  root: {
    padding: 0,
  },
})(MuiAccordionDetails);

export interface IAccordionProps extends MuiAccordionProps {
  title?: string;
  hideExpandIcon?: boolean;
  expanded?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
}

export const CustomizedAccordion: React.FC<IAccordionProps> = ({
  title,
  hideExpandIcon,
  expanded,
  children,
  className,
  onClick,
}) => (
  <div className={className}>
    <Accordion onClick={onClick} expanded={expanded}>
      <AccordionSummary
        expandIcon={!hideExpandIcon && <Icon name={IconVariant.DROP_DOWN_ARROW} />}
        aria-controls="panel1d-content"
        id="panel1d-header"
      >
        {title && (
          <Text variant={TextSize.S} caps>
            {title}
          </Text>
        )}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  </div>
);

export default CustomizedAccordion;
