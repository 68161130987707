import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { MuiTooltip, MuiTooltipProps } from '../mui';

import styles from './styles.module.css';
import { useModalContext } from '../../hooks/useModalRootContainer';

export enum TooltipAlignment {
  CENTER = 'center',
  LEFT = 'left',
}

export enum TooltipBackgroundColor {
  INHERIT = 'inherit',
  BLACK = 'black',
  WHITE = 'white',
  BLUE = 'blue',
}

export interface ITooltipProps extends MuiTooltipProps {
  tooltipWidth?: number | string;
  disabled?: boolean;
  tag?: React.ReactNode;
  cta?: React.ReactNode;
  align?: TooltipAlignment;
  arrowStyles?: string;
  tooltipWrapperClassname?: string;
  tooltipTitleClassName?: string;
  tooltipBackgroundColor?: TooltipBackgroundColor;
  showOnHorizontalTextOverflow?: boolean;
}

const Title = ({ align, tag, title, cta, tooltipTitleClassName }) => (
  <div className={cx(styles.styledContent, styles[align])}>
    {tag && <div className={styles.tag}>{tag}</div>}
    <div className={cx(styles.title, tooltipTitleClassName)}>{title}</div>
    {cta && <div className={styles.cta}>{cta}</div>}
  </div>
);

export const Tooltip: React.FC<ITooltipProps> = ({
  disabled,
  title,
  placement,
  tooltipWidth,
  tag,
  align = TooltipAlignment.CENTER,
  children,
  arrowStyles,
  showOnHorizontalTextOverflow,
  tooltipWrapperClassname,
  tooltipTitleClassName,
  tooltipBackgroundColor = TooltipBackgroundColor.BLACK,
  cta,
  ...props
}) => {
  const [disableTooltipOnOverflow, setDisableTooltipOnOverflow] = useState(false);
  const tooltipRef = useRef<HTMLParagraphElement>();
  const styledTooltipBackground = cx({
    [styles.styledTooltipWhite]: tooltipBackgroundColor === TooltipBackgroundColor.WHITE,
    [styles.styledTooltipBlue]: tooltipBackgroundColor === TooltipBackgroundColor.BLUE,
  });

  const getModalsRootContainer = useModalContext();

  const componentStyles = {
    tooltip: cx(styles.styledTooltip, tooltipWrapperClassname, styledTooltipBackground),
    arrow: cx(styles.styledArrow, arrowStyles),
  };

  useEffect(() => {
    if (showOnHorizontalTextOverflow && tooltipRef.current) {
      const { clientWidth, scrollWidth } = tooltipRef.current;

      if (clientWidth >= scrollWidth) {
        setDisableTooltipOnOverflow(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOnHorizontalTextOverflow, tooltipRef.current]);

  if (disabled) {
    return children;
  }

  let titleComponent = <Title {...{ align, tag, title, cta, tooltipTitleClassName }} />;

  if (tooltipWidth) {
    titleComponent = (
      <div style={{ width: tooltipWidth }}>
        <Title {...{ align, tag, title, cta, tooltipTitleClassName }} />
      </div>
    );
  }

  return (
    <MuiTooltip
      arrow
      title={titleComponent}
      disableHoverListener={disabled || disableTooltipOnOverflow}
      placement={placement}
      ref={tooltipRef}
      PopperProps={{
        container: getModalsRootContainer(),
      }}
      componentsProps={{
        arrow: {
          className: componentStyles.arrow,
        },
        tooltip: {
          className: componentStyles.tooltip,
        },
      }}
      {...props}
    >
      {children}
    </MuiTooltip>
  );
};

export default React.memo(Tooltip);
