import React from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { CollaborationModel } from '@writerai/sidebar-mobx';
import { Text, AvatarSize, UserAvatar, Icon, IconVariant, Tooltip, DotLoader } from '@writerai/ui-atoms';

import styles from './styles.module.css';

export interface ICollaborationPanel {
  className?: string;
  model: CollaborationModel;
  currentUserId?: string;
}

export interface ICollaborationUserAvatar {
  tooltip: string;
  fullName: string;
  email: string | null;
  avatar?: string;
  isTyping?: boolean;
}

const CollaborationUserAvatar: React.FC<ICollaborationUserAvatar> = ({
  tooltip,
  fullName,
  email,
  avatar,
  isTyping,
}) => (
  <Tooltip title={tooltip} placement="top">
    <div className={styles.collaborationUser}>
      <div className={cx(styles.userAvatarWrapper, { [styles.userTyping]: isTyping })}>
        <UserAvatar avatarPath={avatar} size={AvatarSize.M} label={email || ''} fullName={fullName} />
      </div>
      {isTyping && (
        <div className={styles.userLoading}>
          <DotLoader compact />
        </div>
      )}
    </div>
  </Tooltip>
);

export const CollaborationPanel = observer<ICollaborationPanel>(({ className, model, currentUserId }) => {
  const users = model.viewers;
  const typingUser = users.find(user => !!user.isTyping);
  const justOneUserTyping = users.length === 1 && typingUser;

  return (
    <div
      className={cx(styles.wrapper, className, {
        [styles.visible]: users.length,
      })}
    >
      <div className={cx(styles.container, { [styles.justViewing]: !typingUser })}>
        <div className={cx(styles.users, { [styles.usersMargined]: typingUser && !justOneUserTyping })}>
          {users.map(({ id, fullName, email, avatar }) => (
            <CollaborationUserAvatar
              tooltip={currentUserId === id ? 'You (another tab)' : `${fullName || email} \n is viewing`}
              fullName={fullName}
              email={email}
              avatar={avatar}
              isTyping={!justOneUserTyping && typingUser?.id === id}
              key={id}
            />
          ))}
        </div>
        {justOneUserTyping && typingUser && (
          <div className={styles.singleUserTyping}>
            <Text caps>Is typing</Text>
            <DotLoader compact />
          </div>
        )}
        {model.documentIsLocked && (
          <div className={styles.lockedWrapper}>
            <div className={styles.lockedSection}>
              <span className={styles.lockedText}>
                <Text caps>This doc is locked</Text>
              </span>
              <Icon name={IconVariant.LOCK}></Icon>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default CollaborationPanel;
