
const digest = 'f1b2703e1b98d0be074ee08539fd40c4fd6d1c6a0951b6da67e38193f4ca6536';
const css = `._container_m9cri_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 272px;
  height: 49px;
  margin: 4px 0 0;
  padding: 5px 3px;
  background: var(--classic-white);
  border: 1px solid var(--writer-graynu-2);
  box-shadow: var(--menu-box-shadow);
  border-radius: 8px;
}

._controls_m9cri_15 {
  display: flex;
  align-items: center;
  width: 106px;
}

._prevButton_m9cri_21 {
  flex-shrink: 0;
  min-width: auto !important;
  width: 18px !important;
  height: 18px !important;
  margin: 2px 0 0 11px;
}

._prevButtonDisabled_m9cri_29 {
  opacity: 0.3;
}

._nextButton_m9cri_33 {
  flex-shrink: 0;
  min-width: auto !important;
  width: 18px !important;
  height: 18px !important;
  margin: 2px 0 0;
  transform: rotate(180deg);
}

._nextButtonDisabled_m9cri_42 {
  opacity: 0.3;
}

._counter_m9cri_46 {
  flex-shrink: 0;
  width: 70px;
  padding: 3px 10px 0;
  text-align: center;
  font-size: var(--font-size-m);
}

._loader_m9cri_54 {
  flex-shrink: 0;
  width: 70px;
  margin: 0 auto;
  padding: 3px 10px 0;
  transform: scale(0.75);
}

._submitButton_m9cri_62 {
  margin: 0 11px 0 auto;
}

._submitButton_m9cri_62 svg path {
  fill: var(--classic-white);
}
`;

export default {"container":"_container_m9cri_1","controls":"_controls_m9cri_15","prevButton":"_prevButton_m9cri_21","prevButtonDisabled":"_prevButtonDisabled_m9cri_29","nextButton":"_nextButton_m9cri_33","nextButtonDisabled":"_nextButtonDisabled_m9cri_42","counter":"_counter_m9cri_46","loader":"_loader_m9cri_54","submitButton":"_submitButton_m9cri_62"};
export { css, digest };
  