
const digest = '14a1cc0f9b6b77a923e8172bd60506b13e9ed8ae2513bfe8c9284845a1f7ffe1';
const css = `.styles-module__G8975eX {
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
  font-weight: 500;
}

.styles-module__tXLiZCl {
  border-radius: 4px;
  margin-right: auto;
  line-height: 24px;
  user-select: none;
}

.styles-module__nPhYdCP {
  overflow: hidden;
  padding: 4px 8px;
  cursor: pointer;
  text-overflow: ellipsis;
  transition: background-color 0.2s ease-in;
}

.styles-module__rpi3L8Q {
  margin-right: 5px;
}

.styles-module__j6Gv-K7 {
  text-decoration: line-through;
  white-space: pre-wrap;
}

.styles-module__ZXANO5b {
  font-weight: 600;
}

.styles-module__SLn6IMy {
  background-color: var(--writer-green-3);
}

.styles-module__SLn6IMy:hover {
  background-color: var(--writer-green-4);
}

.styles-module__disAB1T {
  background-color: var(--dark-pink);
  color: var(--classic-orange-3);
}

.styles-module__disAB1T:hover {
  background-color: #ffc9ba;
  color: var(--classic-orange-3);
}
`;

export default {"container":"styles-module__G8975eX","issueLabel":"styles-module__tXLiZCl","buttonLike":"styles-module__nPhYdCP","icon":"styles-module__rpi3L8Q","strikethrough":"styles-module__j6Gv-K7","bold":"styles-module__ZXANO5b","correction":"styles-module__SLn6IMy","error":"styles-module__disAB1T"};
export { css, digest };
  