
const digest = '6a47c93b8426d235109ad5614d099d84be6927cde2f0ff923282d92837de7821';
const css = `.styles-module__dlfLfMi {
  --button-height: 36px;

  position: relative;
  display: flex;
  height: var(--button-height);
  align-items: center;
  justify-content: center;
  padding: 0 calc(var(--button-height) / 2.4);
  border: none;
  border-radius: calc(var(--button-height) / 2);
  cursor: pointer;
  font-weight: var(--font-bold-weight);
  text-decoration: none;
  transition: all 0.3s;
  white-space: nowrap;
}

.styles-module__dlfLfMi:focus {
  outline: none;
}

.styles-module__dlfLfMi svg + span,
.styles-module__dlfLfMi i + span {
  margin-left: 7px;
}

.styles-module__dlfLfMi svg path {
  /* stroke: currentColor; */
}

/* ---- Button sizes */
.styles-module__dlfLfMi.styles-module__OkCFKNF {
  --button-height: 20px;

  min-width: 50px;
  font-size: var(--font-size-xs);
}

.styles-module__dlfLfMi.styles-module__fOQ9ndd {
  --button-height: 28px;

  min-width: 70px;
  font-size: var(--font-size-m);
}

.styles-module__dlfLfMi.styles-module__rxLcFWk {
  --button-height: 32px;

  min-width: 86px;
  font-size: var(--font-size-l);
}

.styles-module__dlfLfMi.styles-module__Hj685qJ {
  /* default size */
  --button-height: 40px;

  min-width: 101px;
  font-size: var(--font-size-xl);
}

.styles-module__dlfLfMi.styles-module__gnd6JD6 {
  --button-height: 44px;

  min-width: 112px;
  font-size: var(--font-size-xxl);
}

.styles-module__dlfLfMi.styles-module__qRPt7-B {
  --button-height: 52px;

  min-width: 112px;
  font-size: var(--font-size-xxl);
}

.styles-module__dlfLfMi.styles-module__VpjbVi6 {
  --button-height: 60px;

  min-width: 112px;
  font-size: var(--font-size-xxxl);
}

/* Button sizes end ---- */

.styles-module__dlfLfMi.styles-module__3qfXtPF {
  border: var(--gray-border);
  background-color: var(--classic-white);
  color: var(--classic-grey-3);
}

.styles-module__dlfLfMi.styles-module__3qfXtPF:hover:not(.styles-module__6U15iUc) {
  color: var(--classic-grey-4);
}

.styles-module__dlfLfMi.styles-module__3qfXtPF:active:not(.styles-module__6U15iUc) {
  color: var(--classic-grey-5);
}

.styles-module__dlfLfMi.styles-module__ocO8rLr {
  background-color: var(--writer-blue-5);
  color: var(--classic-white);
}

.styles-module__dlfLfMi.styles-module__ocO8rLr:hover:not(.styles-module__6U15iUc) {
  background-color: var(--avatar-6);
}

.styles-module__dlfLfMi.styles-module__ocO8rLr:active:not(.styles-module__6U15iUc) {
  background-color: var(--writer-blue-4-pressed);
}

.styles-module__dlfLfMi.styles-module__ocO8rLr.styles-module__kDCDz8E {
  background-color: var(--classic-orange-3);
  color: var(--classic-white);
}

.styles-module__dlfLfMi.styles-module__ocO8rLr.styles-module__kDCDz8E:hover:not(.styles-module__6U15iUc) {
  background-color: var(--classic-orange-4);
}

.styles-module__dlfLfMi.styles-module__ocO8rLr.styles-module__kDCDz8E:active:not(.styles-module__6U15iUc) {
  background-color: var(--classic-orange-4);
}

.styles-module__dlfLfMi.styles-module__o6P8T8b {
  background-color: var(--writer-blue-2);
  color: var(--writer-blue-5);
}

.styles-module__dlfLfMi.styles-module__o6P8T8b:hover:not(.styles-module__6U15iUc) {
  background-color: var(--writer-blue-3);
}

.styles-module__dlfLfMi.styles-module__o6P8T8b:active:not(.styles-module__6U15iUc) {
  background-color: var(--writer-blue-3);
}

.styles-module__dlfLfMi.styles-module__o6P8T8b.styles-module__kDCDz8E {
  background-color: var(--classic-orange-1);
  color: var(--classic-orange-3);
}

.styles-module__dlfLfMi.styles-module__o6P8T8b.styles-module__kDCDz8E:hover:not(.styles-module__6U15iUc) {
  background-color: #feece7;
}

.styles-module__dlfLfMi.styles-module__o6P8T8b.styles-module__kDCDz8E:active:not(.styles-module__6U15iUc) {
  background-color: #ffccbe;
}

.styles-module__dlfLfMi.styles-module__XnDdsHi {
  background-color: var(--classic-black);
  color: var(--classic-white);
}

.styles-module__dlfLfMi.styles-module__XnDdsHi:hover:not(.styles-module__6U15iUc) {
  background-color: var(--writer-dark-gray);
}

.styles-module__dlfLfMi.styles-module__XnDdsHi:active:not(.styles-module__6U15iUc) {
  background-color: var(--writer-dark-gray);
}

.styles-module__dlfLfMi.styles-module__bJAcRUx {
  background-color: var(--writer-blue-2);
  color: var(--classic-white);
}

.styles-module__dlfLfMi.styles-module__bJAcRUx:hover:not(.styles-module__6U15iUc) {
  background-color: var(--writer-blue-2);
}

.styles-module__dlfLfMi.styles-module__bJAcRUx:active:not(.styles-module__6U15iUc) {
  background-color: var(--writer-blue-2);
}

.styles-module__dlfLfMi.styles-module__PrTdVjn,
.styles-module__dlfLfMi.styles-module__XYTYTfQ,
.styles-module__dlfLfMi.styles-module__eP2OvKE,
.styles-module__dlfLfMi.styles-module__eP2OvKE.styles-module__6U15iUc {
  background-color: transparent;
  color: var(--black);
}

.styles-module__dlfLfMi.styles-module__eP2OvKE {
  padding: 0;
  font-size: var(--font-size-s);
  font-weight: normal;
}

.styles-module__dlfLfMi.styles-module__PrTdVjn:hover:not(.styles-module__6U15iUc) {
  color: var(--classic-grey-3);
}

.styles-module__dlfLfMi.styles-module__XYTYTfQ:hover:not(.styles-module__6U15iUc),
.styles-module__dlfLfMi.styles-module__XYTYTfQ.styles-module__s-4xWQT {
  background-color: var(--classic-grey-1);
}

.styles-module__dlfLfMi.styles-module__0lAGJD5 {
  border: 1px solid var(--writer-graynu-2);
  background-color: transparent;
  color: var(--black);
}

.styles-module__dlfLfMi.styles-module__0lAGJD5:hover:not(.styles-module__6U15iUc) {
  color: var(--classic-grey-3);
}

.styles-module__dlfLfMi.styles-module__CEObIMS {
  display: inline-block;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  background: transparent;
  color: var(--classic-blue-4);
  font-weight: inherit;
  text-decoration: none;
}

.styles-module__dlfLfMi.styles-module__CEObIMS:hover {
  text-decoration: underline;
}

.styles-module__dlfLfMi.styles-module__4J-5pnS {
  background-color: var(--classic-purple-2);
  color: var(--classic-white);
}

.styles-module__dlfLfMi.styles-module__KbYYue7 {
  background-color: var(--classic-purple-7);
  color: var(--classic-white);
}

.styles-module__dlfLfMi.styles-module__nq-Xiu8 {
  background-color: #ffdbf9;
  color: var(--classic-black);
}

.styles-module__dlfLfMi.styles-module__4J-5pnS:hover:not(.styles-module__6U15iUc) {
  background-color: var(--classic-purple-1);
}

.styles-module__dlfLfMi.styles-module__0OxzO2Y {
  background: #ceffe8;
  color: var(--classic-white);
}

.styles-module__dlfLfMi.styles-module__0OxzO2Y:hover:not(.styles-module__6U15iUc) {
  background: #ceffe8;
}

.styles-module__dlfLfMi.styles-module__6Ca0Gdb {
  background: #ecffc4;
  color: var(--classic-white);
}

.styles-module__dlfLfMi.styles-module__6Ca0Gdb:hover:not(.styles-module__6U15iUc) {
  background: #ecffc4;
}

.styles-module__dlfLfMi.styles-module__ssFfhOu {
  background-color: var(--classic-purple-5);
  color: var(--classic-white);
}

.styles-module__dlfLfMi.styles-module__ssFfhOu:hover:not(.styles-module__6U15iUc) {
  background-color: var(--classic-purple-4);
}

.styles-module__dlfLfMi.styles-module__2jnzfqZ {
  background-color: var(--writer-mauve);
  color: var(--classic-white);
}

.styles-module__dlfLfMi.styles-module__2jnzfqZ:hover:not(.styles-module__6U15iUc) {
  background-color: var(--writer-mauve-dark);
}

.styles-module__dlfLfMi.styles-module__LFAZe-Z {
  background-color: var(--writer-graynu-1);
  color: var(--classic-black);
}

.styles-module__dlfLfMi.styles-module__LFAZe-Z:hover:not(.styles-module__6U15iUc) {
  background-color: var(--writer-graynu-2);
}

.styles-module__dlfLfMi.styles-module__LFAZe-Z:active:not(.styles-module__6U15iUc) {
  background-color: var(--writer-graynu-3);
}

.styles-module__dlfLfMi.styles-module__cxR7UYG .styles-module__PRajSEc,
.styles-module__dlfLfMi.styles-module__FJPd9Pb .styles-module__PRajSEc {
  transform: scale(0.8);
}

.styles-module__dlfLfMi.styles-module__cxR7UYG,
.styles-module__dlfLfMi.styles-module__FJPd9Pb {
  width: var(--button-height);
  min-width: auto;
  padding: 0;
  border-radius: 50%;
}

.styles-module__dlfLfMi.styles-module__FJPd9Pb {
  border-radius: 8px;
}

.styles-module__dlfLfMi.styles-module__cxR7UYG span.styles-module__iqrhxT9,
.styles-module__dlfLfMi.styles-module__FJPd9Pb span.styles-module__iqrhxT9 {
  display: none;
}

.styles-module__dlfLfMi.styles-module__6U15iUc {
  background-color: var(--classic-grey-1);
  color: var(--classic-grey-2);
}

.styles-module__dlfLfMi.styles-module__6U15iUc.styles-module__kDCDz8E {
  background-color: var(--classic-orange-1);
  color: var(--classic-white);
}

.styles-module__dlfLfMi.styles-module__6U15iUc:hover {
  cursor: default;
}

.styles-module__dlfLfMi.styles-module__s-4xWQT {
  pointer-events: none;
}

.styles-module__PRajSEc {
  position: absolute;
  width: 100% !important;
}
`;

export default {"button":"styles-module__dlfLfMi","xxs":"styles-module__OkCFKNF","xs":"styles-module__fOQ9ndd","small":"styles-module__rxLcFWk","medium":"styles-module__Hj685qJ","large":"styles-module__gnd6JD6","xxl":"styles-module__qRPt7-B","xxxl":"styles-module__VpjbVi6","default":"styles-module__3qfXtPF","disabled":"styles-module__6U15iUc","primary":"styles-module__ocO8rLr","danger":"styles-module__kDCDz8E","secondary":"styles-module__o6P8T8b","black":"styles-module__XnDdsHi","blue2":"styles-module__bJAcRUx","transparent":"styles-module__PrTdVjn","transparentHover":"styles-module__XYTYTfQ","transparentLink":"styles-module__eP2OvKE","loading":"styles-module__s-4xWQT","bordered":"styles-module__0lAGJD5","link":"styles-module__CEObIMS","purple2":"styles-module__4J-5pnS","purple3":"styles-module__KbYYue7","pink":"styles-module__nq-Xiu8","green":"styles-module__0OxzO2Y","yellow":"styles-module__6Ca0Gdb","purple":"styles-module__ssFfhOu","mauve":"styles-module__2jnzfqZ","gray":"styles-module__LFAZe-Z","round":"styles-module__cxR7UYG","dotLoader":"styles-module__PRajSEc","squared":"styles-module__FJPd9Pb","content":"styles-module__iqrhxT9"};
export { css, digest };
  