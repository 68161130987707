import React from 'react';
import { IssueCardTypes, rswitch } from '@writerai/common-utils';
import { IssueLabel } from '../../IssueLabel';

import styles from './styles.module.css';

import { Correction } from '../Correction';
import { Icon, IconVariant } from '@writerai/ui-atoms';

interface IIssueHolderProps {
  cardType: IssueCardTypes;
  header?: string;
  highlight: string;
  subHighlight?: string;
  correction: string[];
  onApplySuggestion: (replacement: string) => Promise<unknown>;
}

const IssueHolder = ({
  cardType,
  header,
  highlight,
  subHighlight,
  correction,
  onApplySuggestion,
}: IIssueHolderProps) => (
  <div className={styles.issueHolder}>
    {rswitch(cardType, {
      [IssueCardTypes.ACCEPT_ALL_CHANGES]: (
        <IssueLabel className={styles.acceptAllChangesLabel} style={{ marginTop: 7 }}>
          <Icon name={IconVariant.ARROW_RIGHT_ALT} />
          {correction ? correction[0] : highlight}
        </IssueLabel>
      ),
      [IssueCardTypes.CORRECTION]: (
        <Correction highlight={highlight} correction={correction} onApplySuggestion={onApplySuggestion} />
      ),
      [IssueCardTypes.STRIKETHROUGH_HIGHLIGHT]: (
        <IssueLabel error strikethrough style={{ marginTop: 7 }} onClick={() => onApplySuggestion('')}>
          {highlight}
        </IssueLabel>
      ),
      default: (
        <IssueLabel style={{ marginTop: 7 }}>
          {header || highlight} <span className={styles.subHighlight}>{subHighlight}</span>
        </IssueLabel>
      ),
    })}
  </div>
);

export default React.memo(IssueHolder);
