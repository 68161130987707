
const digest = '92eddacdcab94373669e2b047295e8e36a608d502c12f5e86c6b10e498e1e636';
const css = `.styles-module__ymUaODY {
  position: relative;
  padding: 2px 20px 7px 10px;
  border-radius: 8px 8px 0 0;
  margin: -11px -17px 9px;
  background-color: var(--writer-green-3);
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.styles-module__ymUaODY:hover {
  background-color: var(--writer-green-4);
  cursor: pointer;
}

.styles-module__ymUaODY.styles-module__PmbJoaA:not(.styles-module__XgCOBa-) {
  border-radius: 0 0 8px 8px;
  margin: 4px -17px -17px;
}

.styles-module__MjDhL2f {
  display: flex;
  cursor: default;
  pointer-events: none;
  word-break: break-word;
}

.styles-module__n2zP1QN {
  margin-top: 5px;
  color: var(--classic-grey-3);
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  user-select: none;
}

/* multiple suggestion / correction text then stop higlighting background */
.styles-module__rWPqFtI {
  padding-top: 0;
  padding-bottom: 8px;
  background-color: var(--writer-green-2);
  cursor: default;
  pointer-events: none;
}

.styles-module__rWPqFtI:hover {
  background-color: var(--writer-green-2);
}

.styles-module__rWPqFtI .styles-module__MjDhL2f {
  flex-direction: column;
  align-items: flex-start;
  pointer-events: all;
}

/* single suggestion i.e compact mode */
.styles-module__XgCOBa- {
  padding-right: 11px;
  border-radius: 5px 0 0 5px;
  margin: 0;
}
`;

export default {"container":"styles-module__ymUaODY","containerInverted":"styles-module__PmbJoaA","compact":"styles-module__XgCOBa-","replacementBlock":"styles-module__MjDhL2f","category":"styles-module__n2zP1QN","disableContainerHighlighting":"styles-module__rWPqFtI"};
export { css, digest };
  