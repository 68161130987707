
const digest = '1c3fe90db803eafc27cc52c616e65746a74003525ddc7cdc339e5b75d9ca9a25';
const css = `.styles-module__JxNNRkW {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.styles-module__JxNNRkW.styles-module__JXvyKwE {
  margin-top: 0;
}

.styles-module__Fd48WRm {
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: auto;
  margin-left: -10px;
  color: var(--classic-grey-3);
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.3px;
  line-height: 15px;
  text-transform: uppercase;
  transition: var(--background-color-transition);
}

.styles-module__Fd48WRm:hover {
  background-color: var(--classic-blue-1);
  color: var(--classic-blue-4);
}

.styles-module__yHdG8WA {
  margin-block: 0;
  padding-inline-start: 5px;
}

.styles-module__R2PX92- {
  display: flex;
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-radius: 6px;
  cursor: pointer;
  opacity: 0.7;
  transition: var(--background-color-transition);
}

.styles-module__R2PX92-:hover {
  background-color: var(--classic-grey-1);
  opacity: 1;
}

.styles-module__R2PX92-.styles-module__p-qVXss:hover {
  background-color: var(--classic-orange-0);
}
`;

export default {"controls":"styles-module__JxNNRkW","controlsInverted":"styles-module__JXvyKwE","learnMoreButton":"styles-module__Fd48WRm","itemsList":"styles-module__yHdG8WA","controlButton":"styles-module__R2PX92-","buttonDanger":"styles-module__p-qVXss"};
export { css, digest };
  