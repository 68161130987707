import { IssueCategory, IssueType } from '../types/Issues';

export const DEBUG_APP_CHECK_TOKEN = '39EBB9A6-EF20-4A98-BB83-5F31175EC131';
export const ISSUECARD_SUGGESTIONS_LIMIT = 3;
export const DEFAULT_ISSUE_CARD_COLLAPSED_HEIGHT = 48;
export const NUMBER_OF_CATEGORIES = 8;
export const PLAGIARISM_DEFAULT_SIMILARITY = 95;
export const PLAGIARISM_DEFAULT_HEADER = 'Unoriginal text';
export const PLAGIARISM_MATCHES_LIMIT = 3;
export const SNIPPETS_LOAD_LIMIT = 50;
export const PLAGIARISM_MIN_MATCH_PERCENT = 90;
export const COLLABORATION_ACTIVE_SECONDS = 30;
export const COLLABORATION_LOCK_SECONDS = 5;
export const SNIPPET_CLICK_TO_COPY_TOOLTIP_TEXT = 'Click to copy snippet to clipboard';
export const CATEGORIES_IGNORED_FROM_SIDEBAR = [IssueCategory.Feedback, IssueCategory.Claim, IssueCategory.Quote];
export const ISSUE_TYPES_IGNORED_FROM_SIDEBAR = [IssueType.DICTIONARY];
export const SIDEBAR_MAX_MEDIUM_SIZE = 445;
export const SIDEBAR_MAX_SMALL_SIZE = 367;
export const SIDEBAR_MEDIUM_CATEGORY_SIZE = 138;
export const SIDEBAR_SMALL_CATEGORY_SIZE = 68;
export const NUMBER_OF_WORDS_AROUND_HIGHLIGHT_AS_SEGMENT = 10;

export const TAGS_COUNTER_WIDTH = 40;
export const COLUMNS_WITH_TAGS_VISIBLE = 3;
