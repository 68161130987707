import { render } from 'react-dom';
import { ConfigAppSDK, FieldAppSDK, init, locations } from '@contentful/app-sdk';
import '@contentful/forma-36-react-components/dist/styles.css';
import '@contentful/forma-36-fcss/dist/styles.css';
import '@contentful/forma-36-tokens/dist/css/index.css';
import '@writerai/component-library/dist/index.css';

import { LocalhostWarning } from './components/LocalhostWarning';
import { Config } from './components/Config';
import { Field } from './components/Field';

import './index.css';

const root = document.getElementById('root');

if (window.self === window.top) {
  render(<LocalhostWarning />, root);
} else {
  init((sdk) => {
    const ComponentLocationSettings = [
      {
        location: locations.LOCATION_APP_CONFIG,
        component: <Config sdk={sdk as ConfigAppSDK} />,
      },
      {
        location: locations.LOCATION_ENTRY_FIELD,
        component: <Field sdk={sdk as FieldAppSDK} />,
      },
    ];

    ComponentLocationSettings.forEach((componentLocationSetting) => {
      if (sdk.location.is(componentLocationSetting.location)) {
        render(componentLocationSetting.component, root);
      }
    });
  });
}
