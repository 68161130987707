import React, { useState } from 'react';
import cx from 'classnames';
import { MuiStylesProvider, MuiInput, MuiInputAdornment, MuiInputProps } from '../mui';
import { Button } from '../Button';
import SearchIcon from '../../assets/icons/otherIcons/search.svg';
import CloseIcon from '../../assets/icons/otherIcons/close.svg';

import styles from './styles.module.css';

export interface ISearchBarProps extends MuiInputProps {
  handleClearInput?: (e: React.MouseEvent) => void;
  autoFocusOnClear?: boolean;
  hideClear?: boolean;
}

export const SearchBar = React.forwardRef<HTMLInputElement, ISearchBarProps>(
  ({ className, style, value, handleClearInput, autoFocusOnClear, onChange, hideClear = false, ...props }, ref) => {
    const [focused, setFocus] = useState(false);

    const handleClear = (event: React.MouseEvent) => {
      if (handleClearInput) {
        handleClearInput(event);
      }
    };

    const searchStyles = {
      root: cx(className, styles.searchInputWrapper, {
        [styles.focused]: focused,
        [styles.complete]: !focused && value !== '',
      }),
      input: styles.searchInput,
      error: styles.error,
      disabled: styles.disabledSearch,
      inputTypeSearch: styles.inputSearch,
    };

    return (
      <MuiStylesProvider injectFirst>
        <MuiInput
          autoComplete="off"
          classes={searchStyles}
          type="search"
          inputRef={ref}
          {...{ style, value, onChange }}
          disableUnderline
          fullWidth
          startAdornment={
            <MuiInputAdornment
              position="start"
              classes={{
                root: cx(styles.inputSearchIcon, { [styles.inputSearchIconInactive]: !focused }),
              }}
            >
              <SearchIcon />
            </MuiInputAdornment>
          }
          endAdornment={
            value && !hideClear ? (
              <MuiInputAdornment position="end">
                <Button
                  className={styles.closeButton}
                  icon={<CloseIcon style={{ width: '12px', height: '12px' }} />}
                  onClick={handleClear}
                  round
                />
              </MuiInputAdornment>
            ) : null
          }
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          {...props}
        />
      </MuiStylesProvider>
    );
  },
);

export default SearchBar;
