
const digest = 'd77fa70278a1b2ab3ffea567fb5ee3930d38bd50ac1851f10da2dbd6dfdaadb6';
const css = `._container_14mkh_1 {
  width: 272px;
  max-height: 240px;
  overflow-x: hidden;
  margin: -1px 0 0;
  padding: 5px 3px;
  background: var(--classic-white);
  border: 1px solid var(--writer-graynu-2);
  box-shadow: var(--menu-box-shadow);
  border-radius: 8px;
}

._container_14mkh_1::-webkit-scrollbar {
  width: 0;
}

._containerDisabled_14mkh_17 {
  position: relative;
  max-height: 350px !important;
  padding: 5px 3px 0;
  overflow-x: hidden;
}

._container_14mkh_1 ._menu_14mkh_24 {
  padding: 8px 0;
}

._item_14mkh_28 {
  display: flex;
  padding: 4px 5px;
  border-radius: 3px;
  font-size: var(--font-size-m);
  cursor: pointer;
  user-select: none;
}

._item_14mkh_28:hover {
  background-color: var(--writer-blue-1);
}

._item_14mkh_28 > div {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

._item_14mkh_28 ._icon_14mkh_47 {
  display: inline-flex;
  width: 32px;
  align-items: center;
  justify-content: center;
}

._item_14mkh_28 ._name_14mkh_54 {
  flex-basis: 100px;
  flex-grow: 1;
  margin: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._item_14mkh_28 ._submenuArrow_14mkh_63 {
  margin: 0 6px;
}

._item_14mkh_28 ._submenu_14mkh_63 {
  display: none;
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 261px;
  max-height: 174px;
  width: 239px;
  overflow-y: scroll;
  padding: 8px 3px;
  background: var(--classic-white);
  border: 1px solid var(--writer-graynu-2);
  box-shadow: var(--menu-box-shadow);
  border-radius: 8px;
}

._item_14mkh_28 ._submenu_14mkh_63::-webkit-scrollbar {
  width: 0;
}

._item_14mkh_28:hover ._submenu_14mkh_63 {
  display: block;
}

._submenuTooltipWrapper_14mkh_91 {
  margin-left: 0 !important;
}

._submenuTooltip_14mkh_91 {
  display: box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  max-width: 190px;
  max-height: 90px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

._submenuTooltipSmall_14mkh_106 {
  max-width: 75px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

._itemDisabled_14mkh_113 {
  opacity: 0.3;
  filter: grayscale(1);
}

._itemSeparator_14mkh_118 {
  z-index: 0;
  position: relative;
  padding: 4px 0 7px;
  pointer-events: none;
}

._itemSeparator_14mkh_118 ._name_14mkh_54 {
  text-transform: uppercase;
  padding: 0 10px 0 12px;
  background-color: var(--white);
  letter-spacing: 1.1px;
  font-size: var(--font-size-xxs);
  color: var(--writer-grey-3);
}

._rewriteBlocked_14mkh_134 {
  padding: 9px 12px 7px;
  margin: -6px 12px 4px;
  background-color: var(--writer-blue-2);
  text-transform: uppercase;
  letter-spacing: 1.1px;
  font-size: var(--font-size-xs);
  color: var(--writer-blue-5);
}

._coWriteLimit_14mkh_144 {
  position: sticky;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10px 16px;
  border-bottom: 3px solid var(--white);
  background-color: #f9f9ff;
}

._coWriteLimitHeading_14mkh_154 {
  margin: 0 0 8px;
  padding: 0 0 10px;
  border-bottom: 1px solid var(--writer-blue-2);
  letter-spacing: 3px;
  text-transform: uppercase;
}

._coWriteLimitSubheading_14mkh_162 {
  margin: 0 0 -6px;
  padding: 4px 0;
  letter-spacing: 2.8px;
  font-size: var(--font-size-xxxl);
}

._coWriteLimitSubheading_14mkh_162 i {
  margin: -2px 8px 0 -3px;
}

._coWriteLimitDescription_14mkh_173 {
  line-height: 1.9;
}

._coWriteLimitDescription_14mkh_173 span {
  text-decoration: underline;
  cursor: pointer;
}
`;

export default {"container":"_container_14mkh_1","containerDisabled":"_containerDisabled_14mkh_17","menu":"_menu_14mkh_24","item":"_item_14mkh_28","icon":"_icon_14mkh_47","name":"_name_14mkh_54","submenuArrow":"_submenuArrow_14mkh_63","submenu":"_submenu_14mkh_63","submenuTooltipWrapper":"_submenuTooltipWrapper_14mkh_91","submenuTooltip":"_submenuTooltip_14mkh_91","submenuTooltipSmall":"_submenuTooltipSmall_14mkh_106","itemDisabled":"_itemDisabled_14mkh_113","itemSeparator":"_itemSeparator_14mkh_118","rewriteBlocked":"_rewriteBlocked_14mkh_134","coWriteLimit":"_coWriteLimit_14mkh_144","coWriteLimitHeading":"_coWriteLimitHeading_14mkh_154","coWriteLimitSubheading":"_coWriteLimitSubheading_14mkh_162","coWriteLimitDescription":"_coWriteLimitDescription_14mkh_173"};
export { css, digest };
  