import React, { useMemo, useState, useEffect } from 'react';
import cx from 'classnames';
import {
  ITermExampleBase,
  TermExampleType,
  DropdownOption,
  MultiFieldError,
  TermsPopupFields,
} from '@writerai/common-utils';
import { MuiFormControl, Text, TextSize, Icon, IconVariant } from '@writerai/ui-atoms';

import styles from './styles.module.css';

import { InputGroup, InputTypes } from '../InputGroup';
import ListDropdown, { ListDropdownType } from '../ListDropdown';
import CloseIcon from '../CloseIcon';
import AddNewRowButton from '../AddNewRowButton';

interface IExampleInputGroup {
  examples: ITermExampleBase[];
  onUpdate: (updatedData: ITermExampleBase[], fieldType?: TermsPopupFields.EXAMPLE) => void;
  error?: MultiFieldError;
}

enum ExBoxElement {
  DROPDOWN = 'type',
  TEXT_BOX = 'example',
}

const defaultExampleTypeOptions: DropdownOption[] = [
  {
    id: TermExampleType.Good,
    icon: <Icon name={IconVariant.GREEN1_CHECKMARK} />,
    active: true,
    name: '',
  },
  {
    id: TermExampleType.Bad,
    icon: <Icon name={IconVariant.X_MARK_CIRCLE} />,
    active: false,
    name: '',
  },
];

interface IExampleBoxProps {
  type: ITermExampleBase['type'];
  text: ITermExampleBase['example'];
  onUpdate: (updateType: ExBoxElement, val: string) => void;
  onDelete: () => void;
  error?: string;
  key?: string;
  autoFocus?: boolean;
}

const ExampleBox = ({ key, type, text, onUpdate, onDelete, error, autoFocus }: IExampleBoxProps) => {
  const exampleTypeOptions = useMemo(
    () =>
      defaultExampleTypeOptions.map(option => ({
        ...option,
        active: option.id === type,
      })),
    [type],
  );

  const onExBoxElementChange = (elementType: ExBoxElement, val: unknown) => onUpdate(elementType, val as string);

  return (
    <div className={styles.exampleBox}>
      <div className={styles.inputBoxHolder}>
        <ListDropdown
          key={`dropdown-${key}`}
          className={styles.exampleType}
          dropDownContainerClassName={styles.exampleTypeOptionsContainer}
          options={exampleTypeOptions}
          type={ListDropdownType.SHOW_SELECTED_TRIGGER_ICON}
          onSelect={val => onExBoxElementChange(ExBoxElement.DROPDOWN, val)}
        />
        <InputGroup
          key={`input-${key}`}
          id="example"
          name="example"
          label=""
          className={styles.inputText}
          inputType={InputTypes.INPUT}
          handleChangeInput={e =>
            onExBoxElementChange(ExBoxElement.TEXT_BOX, (e as React.ChangeEvent<HTMLInputElement>).target.value)
          }
          value={text || ''}
          disableHelperText
          errorText={error}
          autoFocus={autoFocus}
        />
      </div>
      <div className={styles.closeIcon}>
        <CloseIcon onClick={onDelete} />
      </div>
    </div>
  );
};

export const ExampleInputGroup: React.FC<IExampleInputGroup> = ({ examples = [], onUpdate, error }) => {
  const [data, setExampleData] = useState<ITermExampleBase[]>(examples);
  const [autoFocus, setAutoFocus] = useState(false);

  const onAddNewRowClick = () => {
    const newRow = {
      type: TermExampleType.Good,
      example: '',
    } as ITermExampleBase;

    setAutoFocus(true);
    setExampleData([...data, newRow]);
  };

  const onDeleteExample = (index: number) => {
    const _updatedData = data.filter((d, i) => i !== index);
    setExampleData(_updatedData);
    onUpdate && onUpdate(_updatedData, TermsPopupFields.EXAMPLE);
  };

  const onExampleDataChange = (updatingElement: ExBoxElement, val: string, index: number) => {
    const _updatedData = data.map((termExample, i) =>
      i === index
        ? {
            ...termExample,
            [updatingElement]: val,
          }
        : termExample,
    );
    setExampleData(_updatedData);
    onUpdate && onUpdate(_updatedData, TermsPopupFields.EXAMPLE);
  };

  useEffect(() => {
    if (error) {
      setExampleData(
        data.map((termExample, i) =>
          error.fieldPosition === i
            ? {
                ...termExample,
                _error: error.message,
              }
            : {
                ...termExample,
                _error: '',
              },
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    setExampleData(examples);
    setAutoFocus(false);
  }, [examples]);

  return (
    <MuiFormControl className={cx(styles.formControl)}>
      <Text variant={TextSize.XL} bold>
        Examples
      </Text>
      {data.map(({ type, example, _error }, index) => (
        <ExampleBox
          key={`exampleBox-${type}-${index + 1}`}
          type={type}
          text={example}
          onUpdate={(updateType: ExBoxElement, val: string) => onExampleDataChange(updateType, val, index)}
          onDelete={() => onDeleteExample(index)}
          error={_error}
          autoFocus={autoFocus}
        />
      ))}
      <AddNewRowButton id="exampleRow" onClick={onAddNewRowClick} label="Add an example" />
    </MuiFormControl>
  );
};

export default ExampleInputGroup;
