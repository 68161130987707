/* eslint-disable react/no-danger */
import React from 'react';
import cx from 'classnames';

import { ITermExampleBase, TermExampleType } from '@writerai/common-utils';

import styles from './styles.module.css';
import { Icon, IconVariant } from '@writerai/ui-atoms';

interface IDescriptionProps {
  description?: string;
  examples?: ITermExampleBase[];
  className?: string;
}

export const Description = ({ description, examples, className }: IDescriptionProps) => (
  <div className={cx(styles.description, styles.scrollbar, className)}>
    <span dangerouslySetInnerHTML={{ __html: description || '' }}></span>
    {examples &&
      examples.map((item, index) => (
        <div key={item.type + index} className={styles.examplesContainer}>
          <span className={styles.examplesIcon}>
            {item.type === TermExampleType.Good ? (
              <Icon name={IconVariant.GREEN2_CHECKMARK} />
            ) : (
              <Icon name={IconVariant.X_MARK_CIRCLE_ORANGE} />
            )}
          </span>
          <span>{item.example}</span>
        </div>
      ))}
  </div>
);
