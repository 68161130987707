
const digest = 'dd574ece84ca6b6f6d2c1396e4467056606270e3314d3007ede555212f5fe8a2';
const css = `.styles-module__YBn65qN {
  position: absolute;
  z-index: 1;
  overflow: auto;
  width: 100%;
  max-height: 250px;
  box-sizing: border-box;
  padding: 0;
  border: var(--writer-graynu-3);
  border-radius: 4px;
  margin: 1px 0 0;
  background-color: var(--classic-white);
  box-shadow: var(--menu-box-shadow);
  list-style: none;
}

.styles-module__YBn65qN li {
  display: flex;
  height: 40px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 15px;
}

.styles-module__YBn65qN li span {
  font-size: 11px;
}

.styles-module__YBn65qN li small {
  color: var(--classic-grey-4);
  font-size: 10px;
}

.styles-module__YBn65qN li[data-focus='true'] {
  background-color: var(--writer-blue-1);
  cursor: pointer;
}

.styles-module__YBn65qN li[data-focus='true'],
.styles-module__YBn65qN li:hover {
  background-color: var(--writer-blue-1);
  cursor: pointer;
}

.styles-module__YBn65qN li[aria-selected='true'],
.styles-module__YBn65qN li[aria-selected='false'] {
  display: flex;
  height: 32px;
  min-height: 32px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  margin: 2px 7px;
  background: var(--classic-white) !important;
  font-size: var(--font-size-s);
  font-weight: var(--font-default-weight);
  line-height: var(--font-size-s);
}

.styles-module__YBn65qN li[aria-selected='true']:hover,
.styles-module__YBn65qN li[aria-selected='false']:hover {
  background: var(--writer-blue-1) !important;
}

.styles-module__YBn65qN li[aria-selected='true'] {
  background: var(--writer-blue-2) !important;
  font-weight: 500;
}

.styles-module__7IAhhki {
  display: flex;
  min-width: 300px;
  min-height: 30px;
  max-height: 140px;
  flex-wrap: wrap;
  padding: 7px 0;
  border: 1px solid var(--writer-graynu-3);
  border-radius: 4px;
  background-color: var(--classic-white);
  overflow-y: scroll;
}

.styles-module__7IAhhki.styles-module__72S-4iQ {
  border: 1px solid var(--writer-blue-3);
  border-radius: 4px;
  box-shadow: var(--input-box-shadow);
}

.styles-module__7IAhhki.styles-module__kLS2R8R,
.styles-module__7IAhhki.styles-module__kLS2R8R input {
  background-color: var(--classic-white);
}

.styles-module__7IAhhki.styles-module__k1GjAml,
.styles-module__7IAhhki.styles-module__k1GjAml input {
  background-color: var(--classic-grey-1);
}

.styles-module__7IAhhki.styles-module__72S-4iQ,
.styles-module__7IAhhki.styles-module__72S-4iQ input {
  background-color: var(--white);
}

.styles-module__7IAhhki:hover {
  border: 1px solid var(--writer-blue-3);
}

.styles-module__7IAhhki input {
  width: 0;
  min-width: 40px;
  height: 30px;
  box-sizing: border-box;
  flex-grow: 1;
  padding: 4px 12px;
  border: 0;
  margin: 0;
  font-size: var(--font-size-m);
  line-height: 24px;
  outline: 0;
}

.styles-module__7IAhhki input::placeholder {
  color: var(--classic-grey-2);
}

.styles-module__eo-Eeap {
  display: flex;
  overflow: hidden;
  height: 21px;
  align-items: center;
  padding: 2px 8px;
  border-radius: 26px;
  margin: 5px 3px 4px;
  background: var(--writer-blue-2);
  outline: 0;
}

.styles-module__EjECn1w {
  background-color: var(--writer-graynu-2);
}

.styles-module__eo-Eeap:hover,
.styles-module__eo-Eeap:focus {
  background-color: var(--writer-blue-3);
}

.styles-module__eo-Eeap span {
  overflow: hidden;
  font-size: 11px;
  line-height: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.styles-module__eo-Eeap svg {
  box-sizing: content-box;
  padding: 2px;
  margin-left: 3px;
  cursor: pointer;
  font-size: var(--font-size-m);
}

.styles-module__FMNEIbG:hover {
  border-radius: 50%;
  background-color: var(--writer-blue-3);
  cursor: pointer;
}

.styles-module__FMNEIbG svg {
  width: 12px;
  height: 12px;
}
`;

export default {"list":"styles-module__YBn65qN","inputWrapper":"styles-module__7IAhhki","focused":"styles-module__72S-4iQ","emptyText":"styles-module__kLS2R8R","disabled":"styles-module__k1GjAml","tag":"styles-module__eo-Eeap","adminTag":"styles-module__EjECn1w","closeIcon":"styles-module__FMNEIbG"};
export { css, digest };
  