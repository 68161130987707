
const digest = '4050c5f7d8bca198215d8116863d9eaa0ba6ef59a8033f8f5af4cc05f0ae7626';
const css = `/* Description section */
.styles-module__N4Yk3vS {
  max-height: 200px;
  padding-right: 16px;
  margin-top: 6px;
  color: var(--black);
  font-size: 11px;
  line-height: 20px;
  overflow-y: scroll;
  white-space: pre-line;
}

.styles-module__N4Yk3vS b {
  font-weight: 600;
}

.styles-module__N4Yk3vS i {
  font-style: italic;
}

.styles-module__N4Yk3vS > span {
  word-break: break-word;
}

.styles-module__atC8q-W {
  display: flex;
  margin-top: 10px;
}

.styles-module__csXW1cp {
  padding-top: 2px;
  margin-right: 10px;
}

.styles-module__csXW1cp svg {
  font-size: 16px;
}

.styles-module__RJMhm1Q::-webkit-scrollbar {
  width: 15px;
}

.styles-module__RJMhm1Q::-webkit-scrollbar-track {
  background: transparent;
}

.styles-module__RJMhm1Q::-webkit-scrollbar-thumb {
  border: 5px solid var(--white);
  border-radius: 15px;
  background: var(--color-support-lightest);
}
`;

export default {"description":"styles-module__N4Yk3vS","examplesContainer":"styles-module__atC8q-W","examplesIcon":"styles-module__csXW1cp","scrollbar":"styles-module__RJMhm1Q"};
export { css, digest };
  