import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import packageJson from "../../../package.json";

const ANONYMOUS_ID_STORAGE_KEY = 'analyticsAnonymousId';

const API_DEV_HOST = 'https://app.qordobadev.com';
const baseUri = window.location.hostname === 'localhost'
  ? API_DEV_HOST
  : window.location.origin.replace('contentful-ai-detector', 'app');
const CLIENT_ID = 'Contentful';

export const trimTextToClosestFoundedCharacter = (text: string, charactersToSearch: string[]) => {
  const closestSentenceEndsIndexes = charactersToSearch.map(char => text.lastIndexOf(char));
  const closestSentenceEndingsIndexesFound = closestSentenceEndsIndexes.filter(closestSentenceEndIndex => closestSentenceEndIndex > 1);
  if (closestSentenceEndingsIndexesFound.length) {
    return text.slice(0, Math.max(...closestSentenceEndingsIndexesFound) + 1);
  }
  return undefined;
};

const getAnonymousId = () => {
  const existingAnonymousId = localStorage.getItem(ANONYMOUS_ID_STORAGE_KEY);
  if (existingAnonymousId) {
    return existingAnonymousId;
  }

  const generatedAnonymousId = uuidv4();
  localStorage.setItem(ANONYMOUS_ID_STORAGE_KEY, generatedAnonymousId);
  return generatedAnonymousId;
};

export const trackEvent = async (name: string, properties: Record<string, string> = {}) => {
  try {
    await axios.post(`${baseUri}/api/analytics/anonymous/track`, {
      eventName: name,
      properties,
      anonymousId: getAnonymousId(),
    }, {
      headers: {
        'x-client': CLIENT_ID,
        'x-client-ver': packageJson.version,
      },
    });
  } catch (error) {
    console.error("Failed to log event:", error);
  }
};
