import React from 'react';

import { IIssueCard } from '@writerai/common-utils';

import styles from './styles.module.css';

import { IssueLabel } from '../../IssueLabel';
import { Description } from '../Description';

export interface InlineDictionaryCardProps extends IIssueCard {}

export const InlineDictionaryCard: React.FC<InlineDictionaryCardProps> = ({
  highlight,
  subHighlight,
  description,
  examples,
}) => (
  <div className={styles.mainContainer}>
    <IssueLabel className={styles.issueLabel}>
      {highlight} <span className={styles.subHighlight}>{subHighlight}</span>
    </IssueLabel>

    <div className={styles.category}>Approved term</div>
    <Description className={styles.description} description={description} examples={examples} />
  </div>
);

export default React.memo(InlineDictionaryCard);
