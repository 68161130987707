
const digest = '0958c9f8646deda07115103b6085f9630bcac08b6e48e89190268b9e4130ef9f';
const css = `div.styles-module__LEpoDBR {
  width: 91px;
}

.styles-module__ef9v6DD {
  display: flex;
  max-width: 145px;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}

.styles-module__ef9v6DD i {
  margin-left: 20px;
  pointer-events: all;
}

.styles-module__czgxwsj {
  line-height: 1.9;
}
`;

export default {"caseSensitiveInputGroup":"styles-module__LEpoDBR","ellipsisTooltip":"styles-module__ef9v6DD","label":"styles-module__czgxwsj"};
export { css, digest };
  