/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import { LearnMoreModalType } from '@writerai/common-utils';
import { Icon, IconVariant, Tooltip, Dropdown, DropdownPlacement } from '@writerai/ui-atoms';

import styles from './styles.module.css';

import { useModalContext } from '../../../hooks/useModalRootContainer';
import LearnMoreModal from '../../LearnMoreModal';

interface IControlsProps {
  learnMore?: LearnMoreModalType;
  onDeleteIssueClick?: () => void;
  onMarkIssueAsWrong: () => void;
  onLearnMoreClicked?: (modalType: LearnMoreModalType) => void;
  className?: string;
  isInverted?: boolean;
  useDropdownPortal?: boolean;
}

export const Controls = ({
  learnMore,
  onDeleteIssueClick,
  onMarkIssueAsWrong,
  onLearnMoreClicked,
  className,
  isInverted,
  useDropdownPortal = true,
}: IControlsProps) => {
  const getModalsRootContainer = useModalContext();
  const dropdownContainer = useDropdownPortal ? getModalsRootContainer() : undefined;
  const [learnMoreModalType, setLearnMoreModalType] = useState<LearnMoreModalType | undefined>();

  const onLearnMoreButtonClick = useCallback(() => {
    if (learnMore) {
      if (onLearnMoreClicked) {
        onLearnMoreClicked(learnMore);
      } else {
        setLearnMoreModalType(learnMore);
      }
    }
  }, [learnMore, onLearnMoreClicked, setLearnMoreModalType]);
  const onCloseLearnMore = useCallback(() => setLearnMoreModalType(undefined), [setLearnMoreModalType]);

  const preventBubbling = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);

  const options = [
    {
      name: 'Suggestion is wrong',
      id: 'wrongSuggestion',
      icon: <Icon name={IconVariant.OUTLINED_FLAG} width={18} height={18} />,
      active: false,
    },
  ];

  return (
    <div
      className={cx(styles.controls, className, {
        [styles.controlsInverted]: isInverted,
      })}
      onClick={preventBubbling}
    >
      {learnMore && Object.values(LearnMoreModalType).includes(learnMore) && (
        <div className={styles.learnMoreButton} onClick={onLearnMoreButtonClick}>
          Learn More
        </div>
      )}

      <Tooltip title="Ignore suggestion">
        <div className={cx(styles.controlButton, styles.buttonDanger)} onClick={onDeleteIssueClick}>
          <Icon name={IconVariant.TRASH}></Icon>
        </div>
      </Tooltip>

      <Dropdown
        options={options}
        tooltip="More options"
        placement={DropdownPlacement.BOTTOM_RIGHT}
        trigger={
          <div className={styles.controlButton}>
            <Icon name={IconVariant.MORE_HORIZ}></Icon>
          </div>
        }
        /* since there is just one option the callback simply fires this action */
        onPrimaryOptionClickAction={onMarkIssueAsWrong}
        domContainer={dropdownContainer}
      />
      {learnMoreModalType && <LearnMoreModal modalType={learnMoreModalType} onCloseModal={onCloseLearnMore} />}
    </div>
  );
};

export default React.memo(Controls);
