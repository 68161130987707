
const digest = 'a0eaeb40f991fb90fd852988fb067024edd5cee9b4f506097277629b5a79392d';
const css = `.styles-module__pncRtUF {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px 12px 16px 27px;
  background: var(--white);
}

.styles-module__UAL6MRc {
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.3px;
  line-height: 15px;
  text-transform: uppercase;
}

.styles-module__eOe8-IX {
  /* works just in chrome */
  /* stylelint-disable-line */
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  overflow: hidden;
  max-height: 32px;
  margin: 0 auto;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

/* Card Header Section */
.styles-module__a4p8sT8 .styles-module__QLOdYUr {
  color: var(--classic-orange-2);
}

.styles-module__JhrcZAR {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  color: var(--classic-grey-3);
}

.styles-module__gvsQr-I {
  display: flex;
}

.styles-module__HSdgAm4 {
  position: absolute;
  display: flex;
  overflow: hidden;
  width: 100%;
}

.styles-module__QLOdYUr {
}

.styles-module__xSqkQvh {
  display: block;
  padding: 2px 6px;
  border-radius: 4px;
  margin-top: -3px;
  margin-left: 10px;
  background: var(--classic-grey-2);
  color: var(--white);
}

.styles-module__-LkUHdv {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -17px;
  max-height: 8px;
  margin: auto 0;
}

.styles-module__KeHbJ-u {
  display: block;
  font-size: 11px;
  line-height: 22px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.styles-module__4dPX2H0 {
  overflow: hidden;
  padding-right: 8px;
  color: var(--black);
  font-size: var(--font-size-m);
  font-weight: normal !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.styles-module__G5xXJm9 {
  width: var(--sidebar-issuecard-width);
  margin: 12px 0;
  box-shadow: var(--issue-card-collapsed-shadow);
  user-select: none;
}

.styles-module__G5xXJm9:last-child {
  margin: 12px 0 0;
}

.styles-module__nnYEd-9 {
  overflow: visible;
  max-width: 100%;
}

.styles-module__G5xXJm9:hover,
.styles-module__nnYEd-9 {
  box-shadow: var(--issue-card-highlighted-shadow);
}

.styles-module__G5xXJm9:not(.styles-module__nnYEd-9) {
  cursor: pointer;
}

.styles-module__HZ1K0uT {
  padding: 6px 8px 0 20px;
  color: var(--dark-blue-grey);
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
}

/* IssueHolder */
.styles-module__b0G6MHy {
  position: relative;
}

.styles-module__b0G6MHy::before {
  position: absolute;
  left: -8px;
  width: 2px;
  height: 100%;
  box-sizing: border-box;
  border-top: 4px solid white;
  border-bottom: 3px solid white;
  background: var(--writer-green-3);
  content: '';
}

.styles-module__ZJ8xOIl {
  padding-left: 5px;
  color: var(--classic-grey-3);
  font-weight: 400;
}

/* Plagiarism Contents */
.styles-module__-8BIeGt {
}

.styles-module__TGi2czj {
  margin-top: 12px;
  color: var(--black);
  font-size: 11px;
}

.styles-module__J5ilsy8 {
  font-size: 13px;
  font-weight: 600;
}

.styles-module__igz1ekV {
  color: #ed6e49;
}

.styles-module__9wu4pIP {
  font-weight: 400;
}

.styles-module__6iWKh5u {
  overflow: hidden;
  color: var(--classic-grey-2);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.styles-module__TSVIZMY {
  color: var(--classic-grey-2);
  font-size: 9px;
  text-decoration: none;
}

.styles-module__TSVIZMY:hover {
  color: var(--dark-grey);
  text-decoration: underline;
}

.styles-module__4Aor0ny {
  display: flex;
  width: 100%;
  height: 36px;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 4px;
  margin-top: 17px;
  background-color: var(--writer-green-2);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  transition: var(--background-color-transition);
}

.styles-module__4Aor0ny:hover {
  background-color: var(--writer-green-3);
}

/* animation */
.styles-module__pncRtUF,
.styles-module__0GsnFYw {
  border-radius: 8px;
}

.styles-module__0GsnFYw {
  transition: var(--box-shadow-transition), max-height var(--issue-card-transition);
}

.styles-module__iNf-t-2 {
  transition: opacity var(--issue-card-transition);
}

.styles-module__HSdgAm4,
.styles-module__gvsQr-I,
.styles-module__TL3ORPZ {
  opacity: 0;
}

.styles-module__UD2HDzP {
  max-height: 48px;
}

.styles-module__UD2HDzP .styles-module__HSdgAm4 {
  opacity: 1;
}

.styles-module__pncRtUF:not(.styles-module__UD2HDzP) .styles-module__gvsQr-I,
.styles-module__pncRtUF:not(.styles-module__UD2HDzP) .styles-module__TL3ORPZ {
  opacity: 1;
}

/* /animation */
`;

export default {"container":"styles-module__pncRtUF","title":"styles-module__UAL6MRc","multiLineEllipsis":"styles-module__eOe8-IX","dontUseCard":"styles-module__a4p8sT8","cardTitle":"styles-module__QLOdYUr styles-module__UAL6MRc","cardHeader":"styles-module__JhrcZAR","categoryLabel":"styles-module__gvsQr-I styles-module__iNf-t-2","preview":"styles-module__HSdgAm4 styles-module__iNf-t-2","betaTag":"styles-module__xSqkQvh styles-module__UAL6MRc","labelColor":"styles-module__-LkUHdv","collapsedDescription":"styles-module__KeHbJ-u","collapsedLabel":"styles-module__4dPX2H0","mainContainer":"styles-module__G5xXJm9","expandedContainer":"styles-module__nnYEd-9","segmentHighlight":"styles-module__HZ1K0uT","acceptAllChangesLabel":"styles-module__b0G6MHy","subHighlight":"styles-module__ZJ8xOIl","plagiarismContainer":"styles-module__-8BIeGt","matchContainer":"styles-module__TGi2czj","matchPercentHolder":"styles-module__J5ilsy8","matchPercentHighlight":"styles-module__igz1ekV","matchDescription":"styles-module__9wu4pIP styles-module__eOe8-IX","matchLinkHolder":"styles-module__6iWKh5u","matchLink":"styles-module__TSVIZMY","acceptAllButton":"styles-module__4Aor0ny","animationContainer":"styles-module__0GsnFYw","animateOpacity":"styles-module__iNf-t-2","content":"styles-module__TL3ORPZ styles-module__iNf-t-2","collapsedContainer":"styles-module__UD2HDzP"};
export { css, digest };
  