import React, { useState } from 'react';
import cx from 'classnames';
import styles from './styles.module.css';

export interface NavTab {
  id: string;
  title: string;
  disabled?: boolean;
  count?: number;
}

export interface NavTabsProps {
  tabs: NavTab[];
  onChange: (tab: NavTab) => void;
  className?: string;
}

export const NavTabs: React.FC<NavTabsProps> = ({ tabs, onChange, className }) => {
  const [selectedTabId, setSelectedTabId] = useState(tabs[0]?.id);

  const onSelect = (tab: NavTab) => {
    setSelectedTabId(tab.id);
    onChange(tab);
  };

  return (
    <nav className={styles.tabsNav}>
      {tabs.map(tab => (
        <button
          key={tab.id}
          className={cx(styles.tabButton, className, {
            [styles.active]: tab.id === selectedTabId,
            [styles.disabled]: tab.disabled,
          })}
          onClick={() => onSelect(tab)}
          type="button"
        >
          <span className={styles.tabTitle}>{tab.title}</span>
          {tab.count !== undefined && <span className={styles.tabCount}>{tab.count}</span>}
        </button>
      ))}
    </nav>
  );
};

export default NavTabs;
