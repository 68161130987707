import React from 'react';
import { Icon, IconVariant } from '@writerai/ui-atoms';
import cx from 'classnames';

import styles from './styles.module.css';

export interface CloseIconProps {
  /** Set additional css class */
  className?: string;
  /** Set inline css styles */
  style?: React.CSSProperties;
  /** Children passed on as React child */
  children?: React.ReactNode;
  iconSize?: number;
  containerSize?: number;
  onClick?: () => void;
}

export const CloseIcon: React.FC<CloseIconProps> = ({ className, iconSize = 14, containerSize = 20, onClick }) => (
  <div className={cx(styles.container, className)}>
    <div className={styles.background} style={{ width: containerSize, height: containerSize }} onClick={onClick}>
      <Icon name={IconVariant.CLOSE} width={iconSize} height={iconSize} />
    </div>
  </div>
);

export default CloseIcon;
