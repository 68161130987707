
const digest = '60f94cf9a9454a15532947bb97f42b81ba5eba77142df159a7a2674d1b3b18c7';
const css = `.styles-module__LFKp8S1 {
  position: relative;
  border: 1px solid var(--writer-graynu-3);
  border-radius: 4px;
  background: var(--classic-white);
  font-family: var(--font-default);
}

.styles-module__LFKp8S1:hover {
  border: 1px solid var(--writer-blue-3);
}

label + .styles-module__LFKp8S1 {
  margin-top: 0;
}

.styles-module__hznJKqf {
  height: auto;
  padding: 8px 15px;
  border-radius: 4px;
  background: var(--classic-white);
  font-size: var(--font-size-m);
  line-height: 24px;
}

.styles-module__deene0V {
}

.styles-module__IPVHdy8 {
  border-color: var(--writer-graynu-3);
}

.styles-module__10Z-9Dm {
  border-color: var(--writer-graynu-1) !important;
  background: var(--writer-graynu-1) !important;
  color: var(--classic-grey-2) !important;
}

.styles-module__10Z-9Dm:hover {
  border-color: var(--writer-graynu-1) !important;
}

.styles-module__deene0V::after {
}

.styles-module__2jw7Y16 {
  border: 1px solid var(--writer-blue-3);
  box-shadow: var(--input-box-shadow);
}

.styles-module__2jw7Y16::after {
}

.styles-module__UhW-61u {
  border: 1px solid var(--classic-orange-1) !important;
  box-shadow: var(--menu-box-shadow);
}

.styles-module__LFKp8S1 textarea {
  font-family: var(--font-default);
  resize: vertical;
}

.styles-module__HOHnsSj {
  border-radius: 8px;
}

.styles-module__HOHnsSj textarea {
  border-radius: 8px;
}

.styles-module__Ew4Yy-z::after {
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 10px;
  height: 15px;
  border: 5px solid transparent;
  border-radius: 0;
  border-top: 7px solid var(--classic-white);
  border-left: 8px solid var(--classic-white);
  background-color: transparent !important;
  content: ' ';
  font-size: 13px;
  line-height: 29px;
  pointer-events: none;
  text-align: center;
}

.styles-module__N367qBH {
  padding: 0;
}

.styles-module__N367qBH textarea {
  height: auto;
  box-sizing: border-box;
  padding: 0 9px;
}

.styles-module__LFKp8S1 input,
.styles-module__LFKp8S1 textarea {
  height: auto;
  padding: 8px 15px;
  border-radius: 4px;
  background: var(--classic-white);
  font-family: var(--font-default);
  font-size: var(--font-size-m);
  line-height: 24px;
}

.styles-module__LFKp8S1 input::placeholder,
.styles-module__LFKp8S1 textarea::placeholder {
  font-size: 12px;
}
`;

export default {"inputWrapper":"styles-module__LFKp8S1","input":"styles-module__hznJKqf","placeHolderShown":"styles-module__deene0V","filled":"styles-module__IPVHdy8","disabled":"styles-module__10Z-9Dm","focused":"styles-module__2jw7Y16","error":"styles-module__UhW-61u","inputRounded":"styles-module__HOHnsSj","inputWrapperMultiline":"styles-module__Ew4Yy-z","multiLine":"styles-module__N367qBH"};
export { css, digest };
  