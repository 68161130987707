
const digest = 'e6df1f0f8e39fe1e5b2209572e10e6f2cc324e40a81e055708a97dff1bc87168';
const css = `.styles-module__TdFfyMb {
  position: relative;
  width: 161px;
}

.styles-module__Yzh4LHR:hover {
  cursor: pointer;
}

.styles-module__lqOs-4W {
  position: absolute;
  z-index: 10;
  width: 275px;
  max-height: 283px;
  padding: 5px 13px;
  border-radius: 8px;
  animation: 0.3s ease 0s 1 normal none running styles-module__1StVNEu;
  background: var(--white);
  box-shadow: var(--modal-box-shadow);
  overflow-y: hidden;
}

@keyframes styles-module__1StVNEu {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.styles-module__lqOs-4W > div {
  position: relative;
  padding: 12px 13px;
}

.styles-module__lqOs-4W > div:first-child {
  display: flex;
  align-items: center;
  padding: 0;
}

.styles-module__lqOs-4W > div:last-child {
  border-bottom-width: 0;
}

.styles-module__AwYIkak {
  height: 53px !important;
  border: 0 !important;
  box-shadow: 0 0 0 0 !important;
  outline: 0 !important;
}

.styles-module__AwYIkak + div {
  display: flex;
  width: 30px;
  height: 26px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 8px;
  background-color: var(--writer-graynu-2);
}

.styles-module__AwYIkak + div:hover {
  background-color: var(--writer-graynu-3);
  cursor: pointer;
}

.styles-module__Gil59pf {
  width: 100% !important;
}

.styles-module__TdvZwER {
  min-width: 50px !important;
  height: 29px !important;
  padding: 0 !important;
}

.styles-module__dXmAiAR {
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-bottom: var(--gray-border);
  gap: 12px;
}

.styles-module__dXmAiAR .styles-module__4kJDhdk {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.styles-module__dXmAiAR > p {
  margin-left: 2px;
}

.styles-module__dXmAiAR > div {
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 12px;
}

.styles-module__Nz4zB5o {
  max-height: 225px;
  border-top: 1px solid var(--writer-graynu-3);
  margin-top: 8px;
  overflow-y: scroll;
}

.styles-module__eQime4L {
  max-height: 230px;
}

.styles-module__7WVJxdy {
  display: flex;
  width: 100%;
  min-height: 30px;
  max-height: 140px;
  flex-wrap: wrap;
  align-items: center;
  padding: 7px 0;
  border-radius: 4px;
  background-color: var(--classic-white);
  overflow-y: scroll;
}

.styles-module__7WVJxdy.styles-module__Lmc3obN,
.styles-module__7WVJxdy.styles-module__Lmc3obN input {
  background-color: var(--classic-white);
}

.styles-module__7WVJxdy.styles-module__E5YOril,
.styles-module__7WVJxdy.styles-module__E5YOril input {
  background-color: var(--classic-grey-1);
}

.styles-module__7WVJxdy.styles-module__lMXbGeK,
.styles-module__7WVJxdy.styles-module__lMXbGeK input {
  background-color: var(--white);
}

.styles-module__7WVJxdy > div {
  margin-right: 6px;
}

.styles-module__7WVJxdy input {
  width: 0;
  min-width: 40px;
  height: 30px !important;
  box-sizing: border-box;
  flex-grow: 1;
  padding: 4px 12px;
  border: 0;
  margin-top: 6px;
  -ms-flex-positive: 1;
  font-size: var(--font-size-m);
  line-height: 24px;
  outline: 0;
}

.styles-module__7WVJxdy input::placeholder {
  color: var(--classic-grey-2);
}

.styles-module__7314K5S {
  padding: 6px;
  border-radius: 50%;
}

.styles-module__7314K5S:hover {
  background-color: var(--writer-blue-3);
}

.styles-module__4wnCCqs {
  display: flex;
}

.styles-module__4wnCCqs > div,
.styles-module__gHZSakn > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.styles-module__gHZSakn > p {
  margin-top: 8px;
  color: var(--writer-grey-3);
}

.styles-module__gHZSakn > div > *:hover {
  cursor: pointer;
}

.styles-module__PmWwEre > i {
  padding-bottom: 4px;
}
`;

export default {"dropDownWrapper":"styles-module__TdFfyMb","selectInputGroup":"styles-module__Yzh4LHR","menuItemsContainer":"styles-module__lqOs-4W","fadeIn":"styles-module__1StVNEu","input":"styles-module__AwYIkak","loading":"styles-module__Gil59pf","loadingCreatedTerm":"styles-module__TdvZwER","createTerm":"styles-module__dXmAiAR","noPaddings":"styles-module__4kJDhdk","showTerms":"styles-module__Nz4zB5o","showTermsSmaller":"styles-module__eQime4L","inputWrapper":"styles-module__7WVJxdy","emptyText":"styles-module__Lmc3obN","disabled":"styles-module__E5YOril","focused":"styles-module__lMXbGeK","closeIcon":"styles-module__7314K5S","termsInput":"styles-module__4wnCCqs","terms":"styles-module__gHZSakn","icon":"styles-module__PmWwEre"};
export { css, digest };
  