import { type ReactEventHandler } from 'react';

import MuiTooltip, { type TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';
import MuiCheckbox from '@mui/material/Checkbox';
import MuiCircularProgress from '@mui/material/CircularProgress';
import MuiLinearProgress from '@mui/material/LinearProgress';
import MuiInputBase from '@mui/material/InputBase';
import MuiInput, { type InputProps as MuiInputProps } from '@mui/material/Input';
import MuiInputAdornment from '@mui/material/InputAdornment';
import MuiAccordion, { type AccordionProps as MuiAccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiModal, { ModalProps as MuiModalProps } from '@mui/material/Modal';
import MuiFade from '@mui/material/Fade';
import MuiFormGroup from '@mui/material/FormGroup';
import MuiFormControl from '@mui/material/FormControl';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiTable from '@mui/material/Table';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableContainer from '@mui/material/TableContainer';
import MuiTableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';
import Popper from '@mui/material/Popper';
import MuiTableCell, { type TableCellProps as MuiTableCellProps } from '@mui/material/TableCell';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MuiTextareaAutosize from '@mui/material/TextareaAutosize';

import MuiStylesProvider from '@mui/styles/StylesProvider';
import withStyles from '@mui/styles/withStyles';
import styled from '@mui/styles/styled';

import useMuiAutocomplete, {
  createFilterOptions as createMuiAutocompleteFilterOptions,
} from '@mui/material/useAutocomplete';
import { usePagination } from '@mui/lab/Pagination';

type VirtualElement = {
  getBoundingClientRect: () => DOMRect;
  contextElement?: Element;
};

interface UsePaginationItem {
  onClick: ReactEventHandler;
  type: 'page' | 'first' | 'last' | 'next' | 'previous' | 'start-ellipsis' | 'end-ellipsis';
  page: number | null;
  selected: boolean;
  disabled: boolean;
}

const muiUtils = {
  withStyles,
  styled,
};

export type {
  MuiTooltipProps,
  MuiTableCellProps,
  MuiInputProps,
  MuiAccordionProps,
  MuiModalProps,
  VirtualElement,
  UsePaginationItem,
};

export {
  // Components
  usePagination,
  MuiTooltip,
  Popper,
  MuiCheckbox,
  MuiCircularProgress,
  MuiLinearProgress,
  ClickAwayListener,
  MuiInput,
  MuiInputAdornment,
  MuiInputBase,
  MuiFormGroup,
  MuiFormControl,
  MuiFormControlLabel,
  MuiTextareaAutosize,
  MuiTable,
  MuiTableBody,
  MuiTableContainer,
  MuiTableHead,
  MuiTableRow,
  MuiTableCell,
  MuiAccordion,
  MuiAccordionSummary,
  MuiAccordionDetails,
  MuiModal,
  MuiFade,
  // Utils
  MuiStylesProvider,
  muiUtils,
  // Hooks
  useMuiAutocomplete,
  createMuiAutocompleteFilterOptions,
};
