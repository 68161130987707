import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';
import { DropdownOption, DropdownActionOption, stopEventPropagation } from '@writerai/common-utils';

import styles from './styles.module.css';

import { useDropdownCoordinates } from './useDropdownCoordinates';
import Text, { TextSize } from '../typography/Text/Text';
import Icon, { IconVariant } from '../Icon';
import { HorizontalLine, LabelledTags } from '../MiscElements';
import Tooltip from '../Tooltip';
import Tag, { TagSize } from '../Tag';
import { Checkbox } from '../Checkbox';
import { useVisible } from '../../hooks/useVisible';
import { ColorDot } from '../ColorDot';

export interface DropdownProps {
  /** Set additional css class */
  className?: string;
  /** Set inline css styles */
  style?: React.CSSProperties;
  /** Children passed on as React child */
  children?: React.ReactNode;
  /** Set dropdown container label */
  label?: string;
  /** Set additional css class for main container */
  containerClassName?: string;
  /** Set additional options css class */
  dropdownItemClassName?: string;
  /** Set additional css class for secondary options container */
  secondaryClassName?: string;
  /** Set additional css class for Dropdown container */
  dropDownContainerClassName?: string;
  /** Component on click on which the Dropdown list is opened */
  trigger: ReactNode;
  /** Placement of Dropdown List */
  placement?: DropdownPlacement;
  /** Options for Dropdown List */
  options: DropdownOption[];
  /** Set secondary action options for Dropdown List */
  secondaryOptions?: DropdownActionOption[];
  /** Set additional action options for Dropdown List */
  additionalOptions?: DropdownActionOption[];
  /** Type of List items. By default(select type) items have "check mark" in the right. With "action" type this mark is absent */
  itemsType?: ItemsTypes;
  /** Set the handler to handle click event */
  onPrimaryOptionClickAction: (id: DropdownOption['id']) => void;
  /** Optional callback triggered when clicking on "trigger" component */
  onTriggerClickCallback?: () => void;
  /** Set additional highlight for action option */
  highlightActiveOption?: boolean;
  /** Enable options length transaction */
  truncated?: boolean;
  /** Enable options multiline display */
  multiLineOptions?: boolean;
  /** Doesn't show highlight color on hover */
  disableHighlightOnHover?: boolean;
  /** shows tooltip when hovering on trigger */
  tooltip?: string;
  /** shows Dropdown for tags */
  withTags?: boolean;
  /** className for the trigger */
  triggerClassName?: string;
  /** enable show on hover */
  showOnHover?: boolean;
  /** use checkbox inputs */
  useCheckbox?: boolean;
  /** hide popup on option click */
  hideOnCLick?: boolean;
  /** dropdown list will be appended to this DOM element via React.Portal */
  domContainer?: Element;
  /** Add additional description on bottom */
  description?: ReactNode;
}

export enum ItemsTypes {
  SELECT = 'selectType',
  ACTION = 'actionType',
  ICON_INLINE = 'iconInlineType',
}

export enum DropdownPlacement {
  BOTTOM_LEFT = 'bottomLeft',
  BOTTOM_RIGHT = 'bottomRight',
  TOP_LEFT = 'topLeft',
  TOP = 'top',
  TOP_RIGHT = 'topRight',
  RIGHT = 'right',
}

interface RenderActionProps {
  options: DropdownActionOption[];
  optionSize?: TextSize;
  className?: string;
  separatorClassName?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
  placement = DropdownPlacement.BOTTOM_LEFT,
  itemsType = ItemsTypes.SELECT,
  label,
  truncated,
  multiLineOptions,
  trigger,
  options,
  onPrimaryOptionClickAction,
  onTriggerClickCallback,
  secondaryOptions,
  additionalOptions,
  highlightActiveOption,
  disableHighlightOnHover,
  tooltip,
  withTags,
  className,
  containerClassName,
  secondaryClassName,
  dropdownItemClassName,
  dropDownContainerClassName,
  triggerClassName,
  showOnHover = false,
  useCheckbox = false,
  hideOnCLick = true,
  domContainer,
  description,
  ...props
}) => {
  const [tooltipDisabled, setTooltipDisabled] = useState(!tooltip);
  const { triggerRef, dropdownRef, isVisible, setIsVisible } = useVisible(false);
  const { debouncedPortalCoords, onUpdatePortalCoords } = useDropdownCoordinates(triggerRef, placement, isVisible);

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (domContainer) {
      onUpdatePortalCoords();
    }

    onTriggerClickCallback && onTriggerClickCallback();
    setIsVisible(!isVisible);
  };
  const onMouseOver = () => {
    if (showOnHover) {
      onTriggerClickCallback && onTriggerClickCallback();
      setIsVisible(true);
    }
  };
  const onMouseOut = () => showOnHover && setIsVisible(false);
  const elClass: string = cx(
    className,
    dropDownContainerClassName,
    styles.dropdownItemsContainer,
    styles[itemsType],
    styles[placement],
  );

  const renderDropdownPrimaryOptions = (actionFn: (id: string) => void) =>
    options?.map(
      ({
        name,
        id,
        active,
        icon,
        warning,
        color,
        action,
        description,
        optionLabel,
        optionLabelColor,
        customOptionName,
        className,
      }: DropdownOption) => {
        const elClass = cx(styles.dropdownItem, dropdownItemClassName, className, {
          [styles.warning]: warning,
          [styles.highlight]: active && highlightActiveOption,
          [styles.withDescription]: !!description,
          [styles.truncated]: truncated,
          [styles.multiLine]: multiLineOptions,
          [styles.disableHover]: disableHighlightOnHover,
          [styles.withTags]: withTags,
          [styles.withCheckbox]: useCheckbox,
          [styles.withLabel]: !!optionLabel,
          [styles.centerIcon]: itemsType === ItemsTypes.ICON_INLINE,
        });

        const _handleClick = (id: string) => {
          // eslint-disable-next-line no-unused-expressions
          action ? action(id) : actionFn(id);

          if (hideOnCLick) {
            setIsVisible(false);
          }
        };

        return (
          <div
            className={elClass}
            onClick={e => {
              e.stopPropagation();

              _handleClick(`${id}`);
            }}
            key={`dropdown-value-${name}-${id}`}
          >
            {useCheckbox && (
              <div className={styles.dropdownItemCheckBox} onClick={stopEventPropagation}>
                <Checkbox
                  checked={active}
                  onClick={() => _handleClick(`${id}`)}
                  className={styles.dropdownItemCheckBoxItem}
                />
              </div>
            )}
            {icon && icon}
            {color && <ColorDot color={color} className={cx(styles.dropdownItemIndicatorWrapper)} />}
            {withTags ? (
              <Tag size={TagSize.S}>{customOptionName || name}</Tag>
            ) : (
              <Text
                variant={TextSize.S}
                medium={active}
                className={cx({
                  [styles.withLabelText]: !!optionLabel,
                  [styles.withLabelText]: !!optionLabel,
                  [styles.textHidden]: itemsType === ItemsTypes.ICON_INLINE,
                })}
              >
                {customOptionName || name}
              </Text>
            )}
            {itemsType !== ItemsTypes.ACTION &&
              (withTags ? (
                <Icon
                  name={active ? IconVariant.BLUE_CHECKMARK : IconVariant.BLUE_UNCHECKEDMARK}
                  className={cx(styles.blueCheckIcon)}
                />
              ) : (
                <Icon
                  name={IconVariant.CHECK}
                  className={cx(styles.checkIcon, {
                    [styles.checkIconHidden]: !active,
                    [styles.textHidden]: itemsType === ItemsTypes.ICON_INLINE,
                  })}
                />
              ))}
            {description && (
              <Text variant={TextSize.XS} className={styles.description}>
                {description}
              </Text>
            )}
            {optionLabel && <LabelledTags bgColor={optionLabelColor}>{optionLabel}</LabelledTags>}
          </div>
        );
      },
    );

  const renderActionOptions = ({
    options,
    optionSize = TextSize.S,
    className,
    separatorClassName,
  }: RenderActionProps) => {
    if (options) {
      const getElClass = (highlighted = false) =>
        cx(styles.dropdownSecondaryContainer, secondaryClassName, styles.dropdownSecondaryItem, dropdownItemClassName, {
          [className!]: highlighted,
        });

      const onActionClick = action => {
        setIsVisible(!isVisible);
        action();
      };

      return (
        <div>
          <HorizontalLine className={cx(styles.lineSeparator, separatorClassName)} />
          {options.map(({ id, action, name, highlighted }: DropdownActionOption) => (
            <div key={id} className={getElClass(highlighted)} onClick={() => onActionClick(action)}>
              <Text variant={optionSize}>{name}</Text>
            </div>
          ))}
        </div>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (tooltip?.length) {
      setTooltipDisabled(isVisible);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const renderDropdownList = () =>
    isVisible ? (
      <div ref={dropdownRef} className={elClass}>
        {label && (
          <div className={styles.dropdownContainerLabel}>
            <Text smallCaps>{label}</Text>
          </div>
        )}

        {renderDropdownPrimaryOptions(onPrimaryOptionClickAction)}

        {secondaryOptions &&
          renderActionOptions({
            options: secondaryOptions,
            separatorClassName: cx({
              [styles.separatorNoPrimaryOptions]: !options.length,
            }),
          })}
        {additionalOptions &&
          renderActionOptions({
            options: additionalOptions,
            className: cx(styles.dropdownAdditional),
          })}
        {description && <div className={styles.descriptionContainer}>{description}</div>}
      </div>
    ) : null;

  const renderDropdownListInPortal = useCallback(
    () =>
      domContainer &&
      debouncedPortalCoords &&
      createPortal(
        <div className={styles.portalContainer} style={debouncedPortalCoords}>
          {renderDropdownList()}
        </div>,
        domContainer,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedPortalCoords, domContainer],
  );

  return (
    <div
      className={cx(containerClassName, styles.dropdownContainer, {
        [styles.noPrimaryOptions]: !options?.length,
      })}
      onFocus={onMouseOver}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseOut}
      onBlur={onMouseOut}
      {...props}
    >
      <Tooltip title={tooltip || ''} disabled={tooltipDisabled}>
        <div
          data-open={isVisible}
          onClick={onClick}
          className={cx(styles.dropdownTrigger, triggerClassName)}
          ref={triggerRef}
        >
          {trigger}
        </div>
      </Tooltip>

      {domContainer ? renderDropdownListInPortal() : renderDropdownList()}
    </div>
  );
};

export type { DropdownOption };
