import React, { useCallback } from 'react';

import { LearnMoreModalType } from '@writerai/common-utils';

import { PassiveVoiceInfo, PeopleFirstInfo, NeutralLangInfo, Icon, IconVariant } from '@writerai/ui-atoms';

import styles from './styles.module.css';

export interface ILearnMoreContent {
  modalType?: LearnMoreModalType;
  onCloseModal?: (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
  hideCloseButton?: boolean;
}

export const LearnMoreContent: React.FC<ILearnMoreContent> = ({ onCloseModal, modalType, hideCloseButton }) => {
  const getLearnMoreModal = useCallback(() => {
    switch (modalType) {
      case LearnMoreModalType.PASSIVE_VOICE:
        return <PassiveVoiceInfo />;
      case LearnMoreModalType.DISABILITY_PEOPLE_FIRST:
        return <PeopleFirstInfo />;
      case LearnMoreModalType.DISABILITY_NEUTRAL_LANGUAGE:
        return <NeutralLangInfo />;
      default:
        return null;
    }
  }, [modalType]);

  return (
    <div>
      {!hideCloseButton ? (
        <div className={styles.closeIcon} onClick={onCloseModal}>
          <Icon name={IconVariant.CLOSE} />
        </div>
      ) : null}
      <div className={styles.modalContainer}>{getLearnMoreModal()}</div>
    </div>
  );
};

export default LearnMoreContent;
