
const digest = '7711a63f4a37b613a3ad28ffa004bd79f8213aea62b9a6d5e5591630c2f769ce';
const css = `.styles-module__98hObtE {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  white-space: nowrap;
}

.styles-module__98hObtE p {
  font-weight: 500;
}

.styles-module__oqmV0rr {
  overflow: hidden;
  margin-left: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.styles-module__5LXvdM3 {
  position: absolute;
  right: 0;
  overflow: hidden;
  padding: 2px 18px;
  border-radius: 3px;
  margin-left: 10px;
  background-color: var(--writer-green-3);
  color: var(--classic-black);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.styles-module__SdVwsxr {
  color: var(--classic-grey-3);
}

.styles-module__1tnes9x {
  display: inline-block;
  margin-left: 4px;
  cursor: pointer;
}
`;

export default {"styledLabel":"styles-module__98hObtE","errorText":"styles-module__oqmV0rr","additionalText":"styles-module__5LXvdM3","requiredMark":"styles-module__SdVwsxr","tooltipContainer":"styles-module__1tnes9x"};
export { css, digest };
  