
const digest = '3c284e077143e5164bf217429340f6fbae6456ee18c1ad6bfee2f02912757f8b';
const css = `.styles-module__3MGv7bn {
  width: 100%;
  height: 100%;
  padding: 20px 0 0;
  margin: 0 auto;
  background: var(--classic-white);
}

.styles-module__R5G8LHK {
  padding: 42px 0 44px;
  background: var(--writer-blue-1);
}

.styles-module__il8NjYY {
  padding: 22px 5px 20px;
}

.styles-module__f-QDMM3 {
  line-height: 25px;
  text-align: center;
}

.styles-module__XBdYfxg {
  padding: 0;
}

.styles-module__XBdYfxg svg {
  display: block;
  margin: 0 auto;
}

.styles-module__ubWQJPb {
  padding: 6px 20px 0;
}

.styles-module__ubWQJPb p {
  display: block;
  text-align: center;
}

.styles-module__-gQoHcO {
  position: relative;
  padding: 0 54px;
}

.styles-module__-gQoHcO p {
  display: block;
  text-align: center;
}

.styles-module__EiMjRw9 {
  padding: 0 14px;
}

.styles-module__xvMLkax {
  padding: 29px 0 30px;
}

.styles-module__xvMLkax svg {
  width: 71px;
  height: 71px;
  margin: auto;
}

.styles-module__EiMjRw9 span {
  display: block;
  font-size: var(--font-size-xxxl);
  line-height: 27px;
  text-align: center;
}

.styles-module__WpkoPNG {
  font-size: var(--font-size-l);
}

.styles-module__GmnbRzB {
  cursor: pointer;
}

.styles-module__Ep4hU-s {
  color: var(--writer-blue-5);
}

.styles-module__Ep4hU-s:hover {
  text-decoration: underline;
}

.styles-module__j9JGWW7 {
  font-weight: var(--font-medium-weight);
}

.styles-module__0UvXnr5 {
  position: absolute;
  right: 0;
  left: 0;
  margin-top: 4px;
  margin-right: auto;
  margin-left: auto;
}

.styles-module__xvYmF3N {
  width: 97px;
}

.styles-module__TNndzuZ {
  width: 225px;
}
`;

export default {"container":"styles-module__3MGv7bn","containerHero":"styles-module__R5G8LHK","containerDescription":"styles-module__il8NjYY","containerText":"styles-module__f-QDMM3","containerHeroIconContainer":"styles-module__XBdYfxg","containerHeroHeadingDescriptionTop":"styles-module__ubWQJPb","containerHeroHeadingDescriptionBottom":"styles-module__-gQoHcO","containerHeroHeading":"styles-module__EiMjRw9","containerHeroLogo":"styles-module__xvMLkax","containerHeadingDescriptionTextTop":"styles-module__WpkoPNG","clickable":"styles-module__GmnbRzB","link":"styles-module__Ep4hU-s","boldText":"styles-module__j9JGWW7","actionTagContainer":"styles-module__0UvXnr5","actionTagContainerShort":"styles-module__xvYmF3N","actionTagContainerLong":"styles-module__TNndzuZ"};
export { css, digest };
  