
const digest = 'ff7d997c3734639207a63ea0233191781f58a5e0538f33af8180bbd0d0162190';
const css = `._container_1dwns_1 {
  display: flex;
  flex-direction: column;
  background: var(--classic-white);
  border: 1px solid var(--writer-graynu-2);
  box-shadow: var(--menu-box-shadow);
  border-radius: 8px;
}

._content_1dwns_10 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

._inputContainer_1dwns_17 {
  padding: 6px 10px 10px;
}

._inputContainer_1dwns_17 ._input_1dwns_17 textarea {
  /* height: 39px !important; */
  overflow: auto !important;
  padding: 10px 35px 10px 10px !important;
  font-weight: var(--font-default-weight) !important;
  font-size: var(--font-size-l) !important;
  resize: none;
}

._inputContainer_1dwns_17 ._input_1dwns_17 textarea[disabled],
._inputContainer_1dwns_17 ._input_1dwns_17 textarea[disabled]:hover {
  border: 1px solid var(--writer-graynu-2) !important;
  background-color: var(--writer-graynu-1) !important;
}

._inputContainer_1dwns_17 ._input_1dwns_17 textarea[disabled] ~ i {
  background-color: var(--writer-graynu-1) !important;
}

._inputContainer_1dwns_17 ._input_1dwns_17 i {
  display: none;
}

._inputContainer_1dwns_17 ._submitButton_1dwns_44 {
  float: right;
  display: block;
  min-width: 24px !important;
  width: 24px;
  height: 24px;
  margin: -32px 7px 0 0;
  padding: 0;
}

._inputContainer_1dwns_17 ._submitButton_1dwns_44 span {
  display: none;
}

._inputContainer_1dwns_17 ._submitButtonDisabled_1dwns_58 {
  background-color: var(--writer-graynu-2);
}
`;

export default {"container":"_container_1dwns_1","content":"_content_1dwns_10","inputContainer":"_inputContainer_1dwns_17","input":"_input_1dwns_17","submitButton":"_submitButton_1dwns_44","submitButtonDisabled":"_submitButtonDisabled_1dwns_58"};
export { css, digest };
  